import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import CssBaseline from "@mui/material/CssBaseline";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'


import { NotificationContext } from 'context/SnackbarProvider.js'
import TeacherService from 'services/teacher.service'

import Container from "@mui/material/Container";
// import Sidebar from "components/Sidebar/Sidebar";
import Fuse from "fuse.js";

// import Sidebar from "components/Sidebar/Sidebar";

import CompanyCardGrid from "components/Grid/CompanyCardGrid";
import CompanyCard from 'components/Cards/CompanyCard'
import CompanyGridSearch from "components/Grid/CompanyGridSearch";
import NewTopPaikkaModal from "components/modal/newTopPaikkaModal";

export default function TopPaikat(props) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { createNotification } = useContext(NotificationContext)

  const getSearchValue = (value) => {
    if (!value) {
      setFilteredData(data);
      return;
    }
    const fuse = new Fuse(data, {
      keys: ["nimi", "esittely"],
      threshold: 0.1,
    });

    const result = fuse.search(value);
    const matches = [];
    if (!result.length || result.length == 0) {
      setFilteredData([]);
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      setFilteredData(matches)
    }
  };

  const handleSettingsOpen = () => {
    setSettingsOpen(true);
  };

  const handleSettingsClose = (created) => {
    if (created == true) {
      createNotification({ message: "Työpaikka luotu!", status: "success" })
    }
    console.log("closed");
    setSettingsOpen(false)
  };

  useEffect(async () => {
    TeacherService.getWorkplaces()
      .then(res => {
        console.log(res.data)
        setData(res.data)
        setFilteredData(res.data)
        setLoading(false)
      }).catch(e => {
        console.log('Error loading workplaces:', e)
      })

  }, []);

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Toppaikat | Ossi</title>
        </Helmet>
      </HelmetProvider>
      {/* <Sidebar ifUserIs={"opettaja"} /> */}

      <CssBaseline />
      <div className="dataMemes">
        <Container maxWidth="lg" sx={{ mx: "auto" }}>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            mt={2}
            mb={4}
          >
            <CompanyGridSearch data={data} search={getSearchValue} />
            <Button variant="contained" onClick={handleSettingsOpen}>
              Add Työpaikka
            </Button>
          </Stack>
          {/* <CompanyCardGrid data={filteredData} /> */}
          <Grid container justifyContent="center" spacing={3} >
            {loading
              ? Array(12).fill().map((_, i) => (
                <Grid key={i} item xs={12} sm={6} lg={4} >
                  <Skeleton sx={{ height: '34vh', mb: -9 }} />
                </Grid>
              ))
              : filteredData.map((company) => (
                <Grid key={company.tp_id} item xs={12} sm={6} lg={4} >
                  <CompanyCard company={company} />
                </Grid>
              ))}
          </Grid>
          <NewTopPaikkaModal
            handleOpen={handleSettingsOpen}
            handleClose={handleSettingsClose}
            open={settingsOpen}
          />
        </Container>
      </div>
    </Fragment>
  );
}
