import React, { useState, useEffect, useContext } from "react"
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Button from '@mui/material/Button'

import GeneralService from 'services/general.service'
import StudentService from 'services/student.service'

import SimpleTeacherPaper from 'components/Papers/SimpleTeacherPaper'
import SimpleStudentPaper from 'components/Papers/SimpleStudentPaper'
import SimpleInstructorPaper from 'components/Papers/SimpleInstructorPaper'
import SimpleStudentCard from "components/Cards/SimpleStudentCard";
import SimpleTeacherCard from "components/Cards/SimpleTeacherCard";
import WorkTaskList from 'components/Lists/WorkTaskList'
import SimpleWorklogCard from "components/Cards/SimpleWorklogCard"
import AddWorklogDialog from "components/Dialogs/AddWorklogDialog"
import AddWorklogCommentDialog from 'components/Dialogs/AddWorklogCommentDialog'

import { format } from 'date-fns'
import useAuth from 'context/useAuth'
import { NotificationContext } from 'context/SnackbarProvider'


function userCards(user, workperiod) {
  console.log('asuup')
  switch (user.userType) {
    case 'opettaja':
      return (workperiod.opiskelija)
    case 'opiskelija':
      return (workperiod.opettaja, workperiod.topohjaajat)
    case 'topohjaaja':
      return (workperiod.opettaja, workperiod.opiskelija)
  }
  return workperiod.opiskelija
}

export default function WorkperiodSummary({ workperiod, refresh }) {

  const [todaysWorklog, setTodaysWorklog] = useState(0)
  const [worklogDialogOpen, setWorklogDialogOpen] = useState(false)
  const [worktasks, setWorktasks] = useState()
  const { user } = useAuth()
  const { createNotification } = useContext(NotificationContext)

  useEffect(() => {
    if (workperiod) {
      if (workperiod.tyolokit.length > 0) {
        console.log('Tyoloki test', workperiod.tyolokit)
        const worklogDate = format(new Date(workperiod.tyolokit[0].alku), "yyyy-MM-dd")
        if (worklogDate == format(new Date(), "yyyy-MM-dd")) {
          console.log('hellooo sumref')
          console.log('hello wl', workperiod.tyolokit[0])
          setTodaysWorklog(workperiod.tyolokit[0])
        } else {
          setTodaysWorklog(undefined)
        }
      }
    } else {
      setTodaysWorklog(undefined)
    }
  }, [workperiod])

  useEffect(() => {
    if (workperiod) {
      GeneralService.getWorkperiodWorktasks(workperiod.tj_id)
        .then(res => {
          console.log('Worktasks: ', res.data.data)
          setWorktasks(res.data.data)
        })
    }
  }, [workperiod])

  const createAwayWorklog = () => {
    console.log('creating away log')
    GeneralService.postAwayWorklog(user, workperiod.tj_id)
      .then(res => {
        console.log(res)
        createNotification({ message: "Poissaolo luotu", status: "success" })
        refresh()

      }).catch(e => console.log('Error:', e))
  }

  return (
    <Paper sx={{ width: '100%' }} elevation={3}>
      <Grid container
        justifyContent='center'
        rowSpacing={1}
        spacing={2}
        pl={2}
        pr={2}
        pt={1}
        pb={1}
      >
        <Grid item align='center' xs={12} >
          <Typography variant='h3'>
            {workperiod
              ? workperiod.toppaikka.nimi
              : <Skeleton />
            }
          </Typography>
        </Grid>
        <Grid item align='center' xs={4} >
          <Typography style={{ fontWeight: 600 }} variant="body1">
            {"Alkupäivä"}
          </Typography>
          <Typography variant="body1">
            {workperiod
              ? workperiod.alku_pvm
              : <Skeleton />
            }
          </Typography>
        </Grid>
        <Grid item align='center' xs={4}>
          <Typography style={{ fontWeight: 600 }} variant="body1">
            {"Loppupäivä"}
          </Typography>
          <Typography variant="body1">
            {workperiod
              ? workperiod.loppu_pvm
              : <Skeleton />
            }
          </Typography>
        </Grid>
        <Grid item align='center' xs={4}>
          <Typography style={{ fontWeight: 600 }} variant="body1">
            {"Lisätieto"}
          </Typography>
          <Typography variant="body1">
            {workperiod
              ? workperiod.lisatieto
              : <Skeleton />
            }
          </Typography>
        </Grid>

        {workperiod
          ? user.userType == 'opettaja'
            ? (
              <Grid item align='center' xs={12} sm={6}>
                <Typography style={{ fontWeight: 600 }} variant="h5">
                  {"Opiskelija"}
                </Typography>
                {workperiod
                  ? <SimpleStudentPaper student={workperiod.opiskelija} />
                  : <Skeleton height={80} />
                }
              </Grid>
            )
            : user.userType == 'opiskelija'
              ? (
                <Grid item align='center' xs={12} sm={6}>
                  <Typography style={{ fontWeight: 600 }} variant="h5">
                    {"Opettaja"}
                  </Typography>
                  {workperiod
                    ? <SimpleTeacherPaper teacher={workperiod.opettaja} />
                    : <Skeleton height={80} />
                  }
                </Grid>
              )
              : (
                <>
                  <Grid item align='center' xs={12} sm={6}>
                    <Typography style={{ fontWeight: 600 }} variant="h5">
                      {"Opiskelija"}
                    </Typography>
                    {workperiod
                      ? <SimpleStudentPaper student={workperiod.opiskelija} />
                      : <Skeleton height={80} />
                    }
                  </Grid>
                  <Grid item align='center' xs={12} sm={6}>
                    <Typography style={{ fontWeight: 600 }} variant="h5">
                      {"Opettaja"}
                    </Typography>
                    {workperiod
                      ? <SimpleTeacherPaper teacher={workperiod.opettaja} />
                      : <Skeleton height={80} />
                    }
                  </Grid>
                </>
              )
          : <Skeleton height={80} />
        }

        {workperiod ?
          workperiod.topohjaajat.length > 0 && (
            workperiod.topohjaajat.length > 1
              ? (
                <Grid item align='center' xs={12}>
                  <Typography style={{ fontWeight: 600 }} variant="h5">
                    {"Ohjaajat"}
                  </Typography>
                  <Grid container spacing={1} >
                    {workperiod.topohjaajat.map((instructor) => (
                      <Grid key={instructor.id} item align='center' xs={6} >
                        <SimpleInstructorPaper instructor={instructor} />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              )
              : (workperiod.topohjaajat[0].id !== user.selfId && user.userType !== 'topohjaaja') && (
                <Grid item align='center' xs={12} sm={6}>
                  <Typography style={{ fontWeight: 600 }} variant="h5">
                    {"Ohjaaja"}
                  </Typography>
                  {workperiod
                    ? <SimpleInstructorPaper instructor={workperiod.topohjaajat[0]} />
                    : <Skeleton height={80} />
                  }
                </Grid>
              ))
          : <Skeleton height={80} />

        }

        <Grid item align='center' xs={12}>
          <Stack justifyContent='center' alignItems='center' width='100%' mt={2}>
            <Typography variant="h5" style={{ fontWeight: 600 }}>
              Työtehtävät
            </Typography>
            <WorkTaskList tasks={(workperiod && worktasks) && worktasks} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={9}>
          <Typography align='center' variant="h5" style={{ fontWeight: 600 }}>
            Päivän työloki
          </Typography>
          {(workperiod && todaysWorklog !== 0) &&
            ((todaysWorklog)
              ? <SimpleWorklogCard data={todaysWorklog} refresh={refresh} />
              : <>
                <Stack direction='row' sx={{ justifyContent: 'center' }} spacing={1}>
                  {user.userType == 'opiskelija' &&
                    <Button variant='contained' onClick={() => setWorklogDialogOpen(true)} >
                      Lisää päivän työloki
                    </Button>
                  }
                  <Button variant='contained' onClick={createAwayWorklog} >
                    Lisää poissaolo
                  </Button>
                </Stack>
                <AddWorklogDialog open={worklogDialogOpen} setOpen={setWorklogDialogOpen} refresh={refresh} />
              </>
            )}
        </Grid>
      </Grid>

    </Paper>
  )
}
