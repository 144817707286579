import React, { useEffect, useState, useCallback, useContext } from 'react'
import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'

import Link from '@tiptap/extension-link'
import Image from '@tiptap/extension-image'

import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight'
// load all highlight.js languages
import { lowlight } from 'lowlight'

// load specific languages only
// import lowlight from 'lowlight/lib/core'
// import javascript from 'highlight.js/lib/languages/javascript'
// lowlight.registerLanguage('javascript', javascript)

import './BaseEditor.scss'
import { styled } from '@mui/material/styles';
import GeneralService from 'services/general.service'
import { NotificationContext } from 'context/SnackbarProvider'
import useAuth from 'context/useAuth'

import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Paper from '@mui/material/Paper'

// Icons
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AddLinkIcon from '@mui/icons-material/AddLink';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import TableRowsIcon from '@mui/icons-material/TableRows';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import CodeIcon from '@mui/icons-material/Code';

const Input = styled('input')({
  display: 'none',
});


const ToolBar = ({ editor, addImage, setLink }) => {
  const buttons = editor
    ? [
      {
        Icon: FormatBoldIcon,
        method: () => editor.chain().focus().toggleBold().run(),
        name: "bold"
      },
      {
        Icon: FormatItalicIcon,
        method: () => editor.chain().focus().toggleItalic().run(),
        name: "italic"
      },
      {
        Icon: FormatListBulletedIcon,
        method: () => editor.chain().focus().toggleBulletList().run(),
        name: "bulletList"
      },
      {
        Icon: FormatUnderlinedIcon,
        method: () => editor.chain().focus().toggleUnderline().run(),
        name: "underline"
      },
      {
        Icon: FormatListNumberedIcon,
        method: () => editor.chain().focus().toggleOrderedList().run(),
        name: "orderedList"
      },
      {
        Icon: AddLinkIcon,
        method: setLink,
        name: "link"
      },
      {
        Icon: FormatQuoteIcon,
        method: () => editor.chain().focus().toggleBlockquote().run(),
        name: "blockquote"
      },
      {
        Icon: CodeIcon,
        method: () => editor.chain().focus().toggleCodeBlock().run(),
        name: "codeblock"
      },
      {
        Icon: TableRowsIcon,
        method: () => console.log("table"),
        name: "table"
      },
      {
        Icon: AddPhotoAlternateIcon,
        method: addImage,
        name: "image"
      }
    ]
    : [];

  return (
    <div className="toolbar-container">
      {buttons.map(({ Icon, method, name }) => {
        if (name === 'image') {
          return (
            <label key={name} htmlFor="icon-button-file">
              <Input accept="image/*"
                id="icon-button-file"
                type="file"
                name="file"
                onChange={addImage}
              />
              <IconButton
                color={editor.isActive(name) ? "primary" : "success"}
                aria-label="upload picture" component="span">
                <Icon />
              </IconButton>
            </label>
          )
        } else if (name === 'link') {
          return (
            <IconButton
              color={editor.isActive(name) ? "primary" : "success"}
              onClick={editor.isActive(name)
                ? () => editor.chain().focus().unsetLink().run()
                : method}
              key={name}
            >
              <Icon />
            </IconButton>
          )
        } else {
          return (
            <IconButton
              color={editor.isActive(name) ? "primary" : "success"}
              onClick={method}
              key={name}
            >
              <Icon />
            </IconButton>
          )
        }
      })}
    </div>
  );
};

export default function BaseEditor({ output, editable = false, toolbar = false, content }) {

  const { themeMode } = useAuth()
  const editor = useEditor({
    editable: editable,
    extensions: [
      StarterKit.configure({
        codeBlock: false
      }),
      Underline,
      Image,
      Link,
      CodeBlockLowlight.configure({
        lowlight,
      }),
    ],
    editorProps: {
      attributes: {
        class: themeMode === 'dark'
          ? 'ProseMirrorDark'
          : 'ProseMirrorLight'
      }
    },
    content: content,
    onUpdate: ({ editor }) => {
      output(editor.getJSON())
    }
  })

  useEffect(() => {
  }, [themeMode])

  // useEffect(() => () => editor?.destroy(), []);
  useEffect(() => {
    editor?.commands.setContent(content)
  }, [content]);

  const { createNotification } = useContext(NotificationContext)

  const uploadImage = async (formData) => {

    return GeneralService.uploadImage(formData)
      .then(res => {
        console.log(res)
        createNotification({ message: 'Kuva ladattu!', status: 'success' })
        return res
      }).catch(err => {
        console.log(err)
        createNotification({ message: 'Kuvaa ei voitu ladata!', status: 'error' })
        return null
      })

  }


  const addImage = useCallback(async (event) => {
    const file = event.target.files[0]
    const formData = new FormData()

    formData.append('file', file, file.name)

    console.log(formData)
    const response = await uploadImage(formData)
    console.log('Response is:', response)
    const filePath = response.data.data.file
    console.log('Asuuh', process.env.REACT_APP_IMAGE_BASE + '/' + filePath)

    if (filePath) {
      editor.chain().focus().setImage({ src: process.env.REACT_APP_IMAGE_BASE + filePath }).run()
    }
  }, [editor])

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  return (
    <Stack width={'100%'} height='100%' maxHeight='100%'>
      <Stack direction="row" maxHeight="100%" width="100%">
        {toolbar &&
          <ToolBar editor={editor} addImage={addImage} setLink={setLink} />
        }
      </Stack>
      {editor &&
        <EditorContent editor={editor} />
      }
    </Stack>
  )
}
