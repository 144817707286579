import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
// import Input from '@mui/material/Input'
import { styled } from '@mui/material/styles';

import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: ['100%', '80%'],
  height: ['100%', '80%'],
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Input = styled('input')({
  display: 'none',
});

export default function NewTopPaikkaModal({ handleOpen, handleClose, open }) {

  const [uploadPath, setUploadPath] = useState("")
  const [uploadError, setUploadError] = useState(false)
  const [topPaikkaData, setTopPaikkaData] = useState({
    nimi: null,
    esittely: null,
    yhteystiedot: null,
    '360linkki': null,
    yrityksenlogo: null,
    vaadittuosaaminen: null,
    korttikoulutukset: null,
    videoesittely: null,
    sijainti: null,
    paikkojenmaara: null
  })

  const handleUpload = ({ target }) => {
    if (target.files.length > 1) {
      setUploadPath("Too many files selected")
      setUploadError(true)
      return
    }

    console.log(target.files[0].name)
    setUploadPath(target.files[0].name)
  }

  const handleCreateClick = async (e) => {
    axios.post(process.env.REACT_APP_API_BASE + '/toppaikat', topPaikkaData)
      .then(res => {
        console.log(res)
        console.log(res.data)
        handleClose(true)
      }).catch(e => {
        console.log(e)
      })
  }

  const handleChange = ({ target }) => {
    let value
    if (target.value == "") {
      value = null
    } else {
      value = target.value
    }
    let data = topPaikkaData
    data[target.id] = value
    setTopPaikkaData(data)
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid container rowSpacing={2} columnSpacing={2} >
              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1}>
                  <TextField
                    fullWidth
                    required
                    id="nimi"
                    label="Nimi"
                    variant="filled"
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  error
                  id="videoesittely"
                  label="Video Esittely"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="esittely"
                  label="Esittely"
                  placeholder="Lisää joku esittely"
                  multiline
                  minRows={3}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="yhteystiedot"
                  label="Yhteystiedot"
                  placeholder="Yhteystiedot"
                  multiline
                  minRows={2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="sijainti"
                  label="Sijainti"
                  placeholder="Sijainti"
                  multiline
                  minRows={2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="vaadittuosaaminen"
                  label="Vaadittu Osaaminen"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="korttikoulutukset"
                  label="Korttikoulutukset"
                  variant="outlined"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  error
                  id="360linkki"
                  label="360 Link"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    disabled
                    fullWidth
                    error={uploadError}
                    id="outlined-disabled"
                    label="File Path"
                    value={uploadPath}
                  />
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple type="file"
                      onChange={handleUpload}
                    />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                </Stack>
              </Grid>

              <Box sx={{ mx: 'auto', pt: 2 }}>
                <Button
                  onClick={handleCreateClick}
                  variant="contained">Luo Työpaikka</Button>
              </Box>


            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div >
  );
}
