import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import axios from "axios"

import GeneralSearch from '../Searches/GeneralSearch'
import InfiScrollCardGrid from '../Grid/InfiScrollCardGrid'
import CompetenceCard from 'components/Cards/Select/CompetenceCard';

export default function SelectCompetenceDialog({ open, setOpen, competences, setCompetences }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(true)
  const [unselectedCompetences, setUnselectedCompetences] = useState([])
  const [competenceSearch, setCompetenceSearch] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_API_BASE + '/osaaminen/')
      .then(res => {
        console.log("Competences", res.data)
        setUnselectedCompetences(res.data)
        setCompetenceSearch(res.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching competences: ", e)
      })
  }, [])


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelected = (competence) => {
    let wt = competences
    wt.push(competence)
    setCompetences(wt)
    let unsel = []

    const ids = wt.map(({ oid }) => oid)
    unselectedCompetences.forEach(x => {
      if (!ids.includes(x.oid)) {
        unsel.push(x)
      }
    })

    setUnselectedCompetences(unsel)
    setCompetenceSearch(unsel)
    console.log(competences)
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valitse osaamiset"}
        </DialogTitle>
        <DialogContent id="competence-scrollable">
          <Stack spacing={2} alignItems="center" mt={2}>
            <GeneralSearch
              data={unselectedCompetences}
              search={setCompetenceSearch}
              search_keys={['kuvaus', 'meta']}
              autocomplete_key={'kuvaus'}
            />

            <InfiScrollCardGrid
              card={CompetenceCard}
              data={competenceSearch}
              handleSelected={handleSelected}
              idKey='oid'

              itemScroll={12}
              scrollableTarget="competence-scrollable"
              scrollThreshold="100px"
            />

            {/* <CompetenceCardGrid competenceSearch={competenceSearch} handleSelected={handleSelected} /> */}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
