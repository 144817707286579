import React, { useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import BaseEditor from '../Editor/BaseEditor'

import { NotificationContext } from '../../context/SnackbarProvider.js'
import axios from 'axios';

export default function EditWorkPeriodPlanDialog(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { handleOpen, handleClose, open, setOpen, tj_id } = props
  const { plan, setPlan } = props

  const [uploadPath, setUploadPath] = useState("")
  const [uploadError, setUploadError] = useState(false)
  const [output, setOutput] = useState()

  const { createNotification } = useContext(NotificationContext)

  const handleUpload = ({ target }) => {
    if (target.files.length > 1) {
      setUploadPath("Too many files selected")
      setUploadError(true)
      return
    }

    console.log(target.files[0].name)
    setUploadPath(target.files[0].name)
  }

  const handleSave = () => {
    console.log("Output is: ")
    createNotification({ message: "Suunnittelu tallennettu", status: "success" })
    axios.put(process.env.REACT_APP_API_BASE + '/topjaksot/' + tj_id, {
      suunnittelu: output
    })
      .then(res => {
        console.log(res.data)
        handleClose(true)
        setPlan(output)
      }).catch(e => {
        console.log(e)
      })
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      scroll="body"
      maxWidth='md'
    >
      <DialogTitle id="responsive-dialog-title">
        {"Näyttösuunnittelun muokkaus"}
      </DialogTitle>
      <DialogContent>
        <Paper sx={{ width: '100%' }} elevation={3} >
          <BaseEditor save={handleSave} editable="true"
            toolbar="true" content={plan} output={setOutput} />
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} autoFocus>
          Save
        </Button>
      </DialogActions>
    </ Dialog>
  );
}
