import React, { useState, useEffect } from 'react';
// import { usePromiseTracker } from "react-promise-tracker";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box'


import Fuse from 'fuse.js'
import axios from 'axios'

import WorkPeriodAccordion from './WorkPeriodAccordion'
import WorkPeriodSearch from './WorkPeriodSearch'


export default function WorkPeriodAccordions({ company }) {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])

  const handleChange = (event, isExpanded) => {
    console.log(isExpanded)
    setExpanded(isExpanded);
  };

  const getSearchValue = (value) => {
    if (!value) {
      setFilteredData(data);
      return;
    }
    const fuse = new Fuse(data, {
      keys: ["alku_pvm", "loppu_pvm", "lisatieto"],
      threshold: 0.1
    })

    const result = fuse.search(value)
    console.log("RESULT: ", result)
    const matches = []
    if (!result.length || result.length == 0) {
      setFilteredData([])
    }
    else {
      result.forEach(({ item }) => {
        matches.push(item)
      })
      setFilteredData(matches)
    }
    console.log("Ayo, ", filteredData)
  }

  useEffect(async () => {
    const result = await axios(process.env.REACT_APP_API_BASE + '/toppaikat/' + company.tp_id)
    console.log(result.data.data)
    setData(result.data.data.topjaksot)
    setFilteredData(result.data.data.topjaksot)
    setLoading(false)
  }, [])
  if (loading) {
    return (
      <span>Loading...</span>
    )
  }
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={company.tp_id + "-content"}
        id={company.tp_id + "-panel"}
        sx={{}}
      >
        <Typography sx={{ width: '15%', flexShrink: 0 }}>
          Topjaksot
        </Typography>
      </AccordionSummary>
      <AccordionDetails>

        {/* {loading ? <Loader /> : null} */}

        <WorkPeriodSearch data={data} search={getSearchValue} />


        {filteredData.map((topjakso) => (
          <WorkPeriodAccordion key={topjakso.tj_id} workperiod={topjakso} />
        ))}

      </AccordionDetails>
    </Accordion>
  );

}

