import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles';

import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: ['100%', '80%'],
  height: ['100%', '80%'],
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Input = styled('input')({
  display: 'none',
});

export default function NewInstructorModal({ handleOpen, handleClose, open, tp_id }) {

  const [instructorData, setInstructorData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    puhelin: null,
  })

  const handleCreateClick = async (e) => {
    axios.post(process.env.REACT_APP_API_BASE + `/toppaikat/${tp_id}/ohjaajat`, instructorData)
      .then(res => {
        console.log(res.data)
        handleClose(true)
      }).catch(e => {
        console.log(e)
      })
  }

  const handleChange = ({ target }) => {
    let value
    if (target.value == "") {
      value = null
    } else {
      value = target.value
    }
    let data = instructorData
    data[target.id] = value
    setInstructorData(data)
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid container rowSpacing={2} columnSpacing={2} >
              <Grid item xs={12} sm={6}>
                <Stack direction="row" spacing={1}>
                  <TextField
                    fullWidth
                    required
                    id="first_name"
                    label="Etunimi"
                    variant="filled"
                    onChange={handleChange}
                  />
                  <TextField
                    fullWidth
                    required
                    id="last_name"
                    label="Sukunimi"
                    variant="filled"
                    onChange={handleChange}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="email"
                  label="Email"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="puhelin"
                  label="Puhelin Numero"
                  onChange={handleChange}
                />
              </Grid>

              <Box sx={{ mx: 'auto', pt: 2 }}>
                <Button
                  onClick={handleCreateClick}
                  variant="contained">Luo Topohjaaja</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div >
  );
}
