import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Link as RouterLink } from 'react-router-dom';

function Row({ workperiod }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {workperiod.tj_id}
        </TableCell>
        <TableCell component="th" scope="row">
          {workperiod.lisatieto}
        </TableCell>
        <TableCell align="right">{workperiod.alku_pvm}</TableCell>
        <TableCell align="right">{workperiod.loppu_pvm}</TableCell>
        <TableCell align="right">{workperiod.opiskelija.nick_name}</TableCell>
        <TableCell align="right">{workperiod.opettaja.nick_name}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, mt: 3, ml: 3 }}>
              <Typography>
                Lisää tietoa tähän
              </Typography>
              <Button component={RouterLink} to={'/topjaksot/all/' + workperiod.tj_id} variant="contained"> Avaa Topjakso </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function WorkPeriodTable({ workperiods }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="right">ID</TableCell>
            <TableCell>Lisätieto</TableCell>
            <TableCell align="right">Alku</TableCell>
            <TableCell align="right">Loppu</TableCell>
            <TableCell align="right">Opettaja</TableCell>
            <TableCell align="right">Opiskelija</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {workperiods.map((workperiod) => (
            <Row key={workperiod.tj_id} workperiod={workperiod} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
