import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'

import WorkTaskInfoDialog from '../../components/Dialogs/WorkTaskInfoDialog'

export default function WorkTasksList({ tasks }) {
  const [checked, setChecked] = useState([0]);
  const [infoOpen, setInfoOpen] = useState(false)
  const [openedTask, setOpenedTask] = useState()

  const handleToggle = (value) => () => {
    console.log(infoOpen)
    console.log('Val', value)
    console.log('Yah', tasks.find(({ id }) => id === value))
    setOpenedTask(tasks.find(({ id }) => id === value))
    setInfoOpen(true)
  };

  return (
    <>
      <List sx={{ width: '100%', maxWidth: 360 }}>
        <Paper elevation={1} >
          {tasks
            ? tasks.map((task) => {
              const labelId = `checkbox-list-label-${task.id}`;

              return (
                <ListItem
                  key={task.id}
                  disablePadding
                >
                  <ListItemButton role={undefined} onClick={handleToggle(task.id)} dense>
                    <ListItemText id={labelId} primary={task.nimike} />
                  </ListItemButton>

                </ListItem>
              );
            })
            : Array(3).fill().map((_, i) => (
              <Skeleton key={i} height={30} />
            ))
          }
        </Paper>
      </List>

      {openedTask &&
        <WorkTaskInfoDialog open={infoOpen} setOpen={setInfoOpen} worktask={openedTask} />
      }
    </>
  );
}
