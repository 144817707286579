import React, { Fragment, Component, useState, useEffect } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";
import axios from 'axios'


import InfiniteScroll from 'react-infinite-scroll-component';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography'

export default function InfiScrollCardGrid(props) {
  const { card: Card, data, handleSelected, idKey = "id" } = props
  const { itemScroll = 20, scrollableTarget, scrollThreshold } = props

  const [items, setItems] = useState(data)
  const [shownItems, setShownItems] = useState(data.slice(0, itemScroll))
  const [hasMore, setHasMore] = useState(true)



  useEffect(() => {
    setItems(data)
    setShownItems(items.slice(0, itemScroll))
    console.log(scrollableTarget)
  }, [data])


  const fetchScrollData = async () => {
    try {
      setShownItems(items.slice(0, shownItems.length + itemScroll))
    } catch (err) {
      console.log(err);
    }
  };

  const fetchMore = () => {
    if (shownItems.length < items.length) {
      setHasMore(true)
      fetchScrollData()
    } else {
      setHasMore(false)
    }
  }


  return (
    <div className="cardgrid" >
      <InfiniteScroll
        dataLength={shownItems.length}
        next={fetchMore}
        hasMore={hasMore}
        loader={<Typography>Loading More...</Typography>}
        endMessage={<Typography>That's all!</Typography>}
        scrollableTarget={scrollableTarget}
        scrollThreshold={scrollThreshold}
      >
        <Grid container justifyContent="center" spacing={3}  >
          {shownItems.map((item) => (
            <Grid key={'item' + item[idKey]} item >
              <Grid item container xs={12} sm={6} md={4} lg={3} >
                <Card {...props} key={item[idKey]} data={item} handleSelected={handleSelected} />
              </Grid>
            </Grid>
          )
          )}
        </Grid>
      </InfiniteScroll>
    </div >
  )
}
