import React, { useState, useContext, useRef } from 'react';
import BaseEditor from 'components/Editor/BaseEditor'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Collapse from '@mui/material/Collapse'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box'

import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { format, formatDistanceToNow, formatDuration, intervalToDuration } from 'date-fns'
import { fi } from 'date-fns/locale'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';

import axios from "axios";

import { CardActionArea } from '@mui/material';
import useAuth from 'context/useAuth'
import GeneralService from 'services/general.service'
import { NotificationContext } from 'context/SnackbarProvider'

import CommentCard from 'components/Cards/CommentCard'
import AddWorklogCommentDialog from 'components/Dialogs/AddWorklogCommentDialog'
import { red } from '@mui/material/colors';



export default function SimpleWorklogCard({ data: worklog, refresh }) {

  const { user } = useAuth()
  const { createNotification } = useContext(NotificationContext)
  const [expanded, setExpanded] = useState(false)
  const [addCommentDialogOpen, setAddCommentDialogOpen] = useState(false)


  const [alreadyCommented, setAlreadyCommented] = useState(() => {
    if (worklog.kommentit.some((comment) => comment.ohjaaja)) {
      return true
    }
    return false
  })

  // Menu handles
  const handleMenuClick = (popup, s) => {
    popup.close(s)
  };

  const handleMenuDelete = (popup) => {
    console.log('Delete menu', 'hello')
    axios.delete(process.env.REACT_APP_API_BASE + `/${user.userType}/topjaksot/${worklog.tj_id}/tyolokit/${worklog.id}`)
      .then(res => {
        console.log('Success', res)
        createNotification({ message: 'Tyoloki poistettu!', status: 'success' })
        refresh()
      })
      .catch(e => {
        console.log('Error', e)
        createNotification({ message: 'Tyolokin poistossa oli virhe!', status: 'error' })
      })
    popup.close()

  }

  // Expansions and other handles
  const handleExpandClick = () => {
    setExpanded(!expanded);
    console.log("Worklog is", worklog)
  };

  const handleEditPlanOpen = () => {
    console.log('ye')
  }

  const getDate = (date) => {
    return format(new Date(date), 'dd/MM/yyyy')
  }

  const getDuration = (alku, loppu) => {
    if (worklog.kesto !== '00:00:00') {
      let duration = intervalToDuration({
        start: new Date(alku),
        end: new Date(loppu)
      })
      return formatDuration(duration, { addSuffix: true, locale: fi })
    } else {
      return "Poissaolo"
    }
  }

  const getSince = (alku) => {
    return formatDistanceToNow(new Date(alku), { addSuffix: true, locale: fi })
  }

  const handleQuickComment = (msg) => {
    console.log(msg)
    const finalComment = { kommentti: msg }

    GeneralService.addWorklogComment(user, worklog.tj_id, worklog.id, finalComment)
      .then(res => {
        console.log('Success', res.data)
        createNotification({ message: "Kommentti lisätty", status: "success" })
        setAlreadyCommented(true)
        refresh()
      })
      .catch(e => {
        console.log('Error', e)
      })
  }


  return (
    <Card elevation={2}>
      <Stack direction="row" alignItems='center'>
        <CardActionArea onClick={handleExpandClick}>
          <CardContent>
            <Stack direction='row'>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" noWrap>
                    {getDuration(worklog.alku, worklog.loppu)}
                  </Typography>
                  <Typography variant="body2" noWrap>
                    {getSince(worklog.alku)}
                  </Typography>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Typography style={{ fontWeight: 600 }} variant="caption" >
                    {"Alku"}
                  </Typography>
                  <Typography variant="body1" noWrap>
                    {getDate(worklog.alku)}
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography style={{ fontWeight: 600 }} variant="caption">
                    {"Loppu"}
                  </Typography>
                  <Typography variant="body1" noWrap>
                    {getDate(worklog.loppu)}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </CardContent>
        </CardActionArea>
        {user.userType === 'opiskelija' &&
          <CardContent sx={{ pl: 0, pr: 0 }}>
            <PopupState variant="popover" popupId="worklog-popup-menu">
              {(popupState) => (
                <>
                  <IconButton
                    {...bindTrigger(popupState)} id="menu-button"
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={(s) => handleMenuClick(popupState, s)}>

                      <ListItemIcon>
                        <EditIcon color="primary" />
                      </ListItemIcon>
                      <ListItemText>
                        Muokkaa
                      </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleMenuDelete(popupState)}>
                      <ListItemIcon>
                        <DeleteIcon sx={{ color: red[600] }} />
                      </ListItemIcon>
                      <ListItemText>
                        Poista
                      </ListItemText>
                    </MenuItem>
                  </Menu>
                </>
              )}
            </PopupState>
          </CardContent>
        }
      </Stack>
      {(user.userType === 'topohjaaja'
        && !alreadyCommented
        && worklog.kesto !== '00:00:00') &&

        <CardContent>
          <Grid container justifyContent="center" spacing={1}>
            {['Kiitettävä!', 'Kehuttava osaaminen!', 'Harjoittele vielä!'].map(btnText => (
              <Grid key={btnText} item>
                <Button
                  variant='outlined'
                  onClick={() => handleQuickComment(btnText)}
                >{btnText}</Button>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      }
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography style={{ fontWeight: 600 }} variant="caption">
            {"Kuvaus"}
          </Typography>
          <Stack spacing={2}>
            <Paper elevation={3} sx={{ border: '1px solid gray' }}>
              <BaseEditor content={JSON.parse(worklog.kuvaus)} />
            </Paper>
            <Typography style={{ fontWeight: 600 }} variant='caption'>
              {"Kommentit"}
            </Typography>
            <Stack spacing={1}>
              {
                worklog.kommentit &&
                worklog.kommentit.map((comment) => (
                  <CommentCard key={comment.id} data={comment} refresh={refresh} />
                ))}
            </Stack>
            <Button variant='contained'
              onClick={() => setAddCommentDialogOpen(true)}>
              Lisää Kommentti
            </Button>
          </Stack>
        </CardContent>
        <AddWorklogCommentDialog open={addCommentDialogOpen} setOpen={setAddCommentDialogOpen} worklog={worklog} refresh={refresh} />
      </Collapse>
    </Card>
  );
}
