import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import axios from "axios"

import GeneralSearch from '../Searches/GeneralSearch'
import InfiScrollCardGrid from '../Grid/InfiScrollCardGrid'
import DegreePartCard from 'components/Cards/Select/DegreePartCard'

export default function SelectDegreePartDialog({ open, setOpen, degreeParts, setDegreeParts }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(true)
  const [unselectedDegreeParts, setUnselectedDegreeParts] = useState([])
  const [degreePartSearch, setDegreePartSearch] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_API_BASE + "/tutkinnonosa/")
      .then(res => {
        console.log("DegreeParts", res.data)
        setUnselectedDegreeParts(res.data)
        setDegreePartSearch(res.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching degreeParts: ", e)
      })
  }, [])


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelected = (degreePart) => {
    let wt = degreeParts
    wt.push(degreePart)
    setDegreeParts(wt)
    let unsel = []

    const ids = wt.map(({ to_id }) => to_id)
    unselectedDegreeParts.forEach(x => {
      if (!ids.includes(x.to_id)) {
        unsel.push(x)
      }
    })

    setUnselectedDegreeParts(unsel)
    setDegreePartSearch(unsel)
    console.log(degreeParts)
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth='md'
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valitse tutkinnonosat"}
        </DialogTitle>
        <DialogContent id="degreepart-scrollable">
          <Stack spacing={2} alignItems="center" mt={2}>
            <GeneralSearch
              data={unselectedDegreeParts}
              search={setDegreePartSearch}
              search_keys={['to_nimi', 'to_koodi', 'to_epkoodi', 'to_ammattitaitovaatimukset']}
              autocomplete_key={'to_nimi'}
            />
            {/* <DegreePartCardGrid degreePartSearch={degreePartSearch} handleSelected={handleSelected} /> */}
            <InfiScrollCardGrid
              card={DegreePartCard}
              data={degreePartSearch}
              handleSelected={handleSelected}
              idKey='to_id'

              itemScroll={12}
              scrollableTarget="degreepart-scrollable"
              scrollThreshold="100px"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
