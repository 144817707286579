import React, { Fragment, Component, useState, useEffect } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";
import axios from 'axios'


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function WorkPeriodSearch({ data, search }) {
  // search("hello")

  const searchChange = (e) => {
    search(e.target.value)
  }

  return (
    <Box
      sx={{
        width: "60%",
      }}
    >

      <TextField
        id="outlined-search"
        label="Search field"
        type="search"
        onChange={searchChange} />

    </Box>
  )
}
