import React, { useState, useEffect } from 'react'
import { Navigate, useLocation, Outlet, BrowserRouter as Router, Routes, Route } from "react-router-dom";


import logo from './assets/images/favicon.ico';
import './App.css';

import { Layout } from './Layout.js'
import Sidebar from './components/Sidebar/Sidebar'
import Login from './pages/Login'
import TopLogin from './pages/TopLogin'
import Unauthorized from './pages/Unauthorized'
import NotFound from './pages/NotFound'

import { NotificationProvider } from "./context/SnackbarProvider"
import useAuth, { AuthProvider } from "./context/useAuth";


import StudentWorkPeriods from "./pages/students/StudentWorkPeriods"
import StudentWorkPeriod from "./pages/students/StudentWorkPeriod"
import Test123 from './pages/students/Test123'

// Opettajat Pages
import TeacherFrontpage from './pages/teachers/TeacherFrontpage'
import TopPaikat from "./pages/teachers/TopPaikat";
import TopPaikka from './pages/teachers/TopPaikka'
import WorkPeriod from './pages/teachers/WorkPeriod'

// Työpaikoille Pages
import InstructorFrontpage from './pages/instructors/InstructorFrontpage'
import InstructorStudentPage from './pages/instructors/InstructorStudentPage'

// General
import ProfileSettings from './pages/instructors/ProfileSettings'


import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#8dc63f'
    },
  },
});

const AuthRoute = ({ allowedRoles }) => {
  const { user } = useAuth()
  const location = useLocation()



  if (!allowedRoles) {
    return user
      ? <Outlet />
      : <Navigate to="/login" state={{ from: location }} replace />
  }

  if (allowedRoles) {
    return allowedRoles.includes(user?.userType)
      ? <Outlet />
      : user
        ? <Navigate to="/unauthorized" state={{ from: location }} replace />
        : <Navigate to="/login" state={{ from: location }} replace />
  }

}

const TopAuth = () => {
  const { user } = useAuth()
  const location = useLocation()

  return user?.userType === 'topohjaaja'
    ? <Outlet />
    : user
      ? <Navigate to="/unauthorized" state={{ from: location }} replace />
      : <Navigate to="/toplogin" state={{ from: location }} replace />

}

function Frontpage() {
  const { user } = useAuth()
  switch (user.userType) {
    case 'opiskelija':
      return <StudentWorkPeriods />
    case 'opettaja':
      return <TeacherFrontpage />
    case 'topohjaaja':
      return <InstructorFrontpage />
  }
}

function App() {


  return (
    <Router>
      <AuthProvider>
        <NotificationProvider>
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/toplogin" element={<TopLogin />} />
            <Route exact path="/unauthorized" element={<Unauthorized />} />
            <Route exact path="*" element={<NotFound />} />
            <Route exact path='/' element={
              <AuthRoute />
            } >
              {/* {StudentRoutes()} */}
              <Route path='/' element={<Sidebar />} >

                <Route element={<AuthRoute allowedRoles={['opiskelija', 'opettaja', 'topohjaaja']} />} >
                  <Route path="/" element={<Frontpage />} />
                  <Route path="/profiili" element={<ProfileSettings />} />
                </Route>

                <Route element={<AuthRoute allowedRoles={['opiskelija', 'opettaja']} />} >
                  <Route path="/topjaksot/" element={<StudentWorkPeriods />} />
                  <Route path="/topjaksot/:tj_id" element={<StudentWorkPeriod />} />
                </Route>


                <Route element={<AuthRoute allowedRoles={'opettaja'} />} >
                  <Route path="/toppaikat/all/" exact element={<TopPaikat />} />
                  <Route path="/toppaikat/all/:tp" element={<TopPaikka />} />
                  <Route path="/topjaksot/all/:tj_id" element={<WorkPeriod />} />
                </Route>

                {/* Router for TOP harjoittelu */}
                <Route element={<AuthRoute allowedRoles={'topohjaaja'} />} >
                  <Route path="/oppilaat/:uid" element={<InstructorStudentPage />} />
                </Route>
              </Route>
            </Route>
            <Route exact path="/top/" element={<TopAuth />} >
              <Route element={<Sidebar />} >
                <Route path="/top/" element={<InstructorFrontpage />} />
                <Route path="/top/oppilaat/:uid" element={<InstructorStudentPage />} />
              </Route>
            </Route>
          </Routes>
        </NotificationProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
