import axios from "axios";
const API_URL = process.env.REACT_APP_API_BASE + "/auth/";
class AuthService {
  async login(email, password) {
    return axios
      .post(API_URL + "login", {
        email: email,
        password: password
      })
      .then(res => {
        if (res.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(res.data));
        }
        return res.data;
      }).catch(e => {
        if (e.response) {
          console.log('Error response:', e.response.data)
          throw new Error(e.response.data.message)
        } else {
          console.log('Error:', e)
          throw new Error('Jotain tapahtui väärin.')
        }
      })
  }
  logout() {
    localStorage.removeItem("user");
  }
  register(email, password) {
    return axios.post(API_URL + "signup", {
      email,
      password
    });
  }
  getCurrentUser() {

    return Promise.resolve(JSON.parse(localStorage.getItem('user')))
  }
}
export default new AuthService();
