import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography';


export default function TeacherCard({ data: teacher, handleSelected }) {

  return (
    <Card sx={{ minWidth: 100 }}>
      <CardActionArea onClick={() => handleSelected(teacher)}>
        <CardContent>
          <Typography variant="h5" component="div">
            {teacher.nick_name}
          </Typography>
          <Typography variant="body2">
            {teacher.first_name + ' ' + teacher.last_name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
