import axios from 'axios';
import authHeader from './auth-header';

const BASE = process.env.REACT_APP_API_BASE

const T_API_URL = BASE + '/opettaja/';
const S_API_URL = BASE + '/opiskelija/';
const I_API_URL = BASE + '/ohjaaja/';
const WORKPERIOD_API_URL = BASE + '/topjaksot/'
const WORKTASK_API_URL = BASE + '/tyotehtavat/'
const COMPETENCE_API_URL = BASE + '/osaaminen/'
const WORKPERIOD_COMMENT_API_URL = BASE + '/topkommentit/'
const IMAGES_API_URL = BASE + '/kuvat/'


function userApiSwitch(user) {
  switch (user.userType) {
    case 'opettaja':
      return T_API_URL
    case 'opiskelija':
      return S_API_URL
    case 'topohjaaja':
      return I_API_URL
  }
}

class GeneralService {
  addWorklogComment(user, tj_id, worklog_id, comment) {
    return axios.post(userApiSwitch(user) + 'topjaksot/' + tj_id + '/tyolokit/' + worklog_id, comment, { headers: authHeader() })
  }

  addPlanComment(user, tj_id, comment) {
    return axios.post(userApiSwitch(user) + 'topjaksot/' + tj_id + '/kommentit/', comment, { headers: authHeader() })
  }

  postWorklog(user, tj_id, worklog) {
    return axios.post(userApiSwitch(user) + 'topjaksot/' + tj_id + '/tyolokit/', worklog, { headers: authHeader() })
  }

  postAwayWorklog(user, tj_id) {
    const worklog = {
      alku: new Date(),
      loppu: new Date(),
      kesto: 0,
      kuvaus: 'Poissaolo!'
    }

    return axios.post(userApiSwitch(user) + 'topjaksot/' + tj_id + '/tyolokit/', worklog, { headers: authHeader() })
  }

  getWorkperiodWorktasks(tj_id) {
    return axios(WORKPERIOD_API_URL + tj_id + '/tyotehtavat/', { headers: authHeader() })
  }

  updateCompetenceCompletion(oid, uid, state) {
    return axios.put(COMPETENCE_API_URL + oid + '/' + uid, { state: state }, { headers: authHeader() })
  }

  deleteWorkperiodComment(commentId) {
    return axios.delete(WORKPERIOD_COMMENT_API_URL + commentId, { headers: authHeader() })
  }

  uploadImage(formData) {
    return axios.post(IMAGES_API_URL, formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          ...authHeader()
        }
      })
  }

}
export default new GeneralService();
