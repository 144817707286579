
import React, { Fragment, Component, useState, useEffect } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Link from '@mui/material/Link'

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import { CardActionArea } from '@mui/material';


export default function SimpleStudentCard({ data: student, handleSelected }) {

  return (
    <Card sx={{ minWidth: 150 }}>
      <CardActionArea onClick={() => handleSelected(student)}>
        <CardContent>
          <Typography variant="h5">
            {student.first_name + ' ' + student.last_name}
          </Typography>
          {student.nick_name &&
            <>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {"Nimimerkki: "}
              </Typography>
              <Typography variant="body1" display="inline">
                {student.nick_name}
              </Typography>
            </>
          }
          <Typography />
          {student.discord_id && (
            <>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {"Discord: "}
              </Typography>
              <Typography variant="body1" display="inline">
                {student.discord_id}
              </Typography>
            </>
          )}
          <Typography />
          {student.puhelin && (
            <Typography variant="body1" display="inline">
              <Link variant='inherit' underline='hover'
                onMouseDown={event => event.stopPropagation()}
                onClick={event => {
                  event.stopPropagation();
                }}
                href={'tel:' + student.puhelin} >
                <PhoneIcon
                  fontSize='small' color='primary' style={{ verticalAlign: "middle" }} />
                {' ' + student.puhelin}
              </Link>
            </Typography>
          )}
          <Typography />
          {student.oma_email && (
            <Typography variant="body1" display="inline">
              <Link variant='inherit' underline='hover'
                href={'mailto:' + student.oma_email}
                onMouseDown={event => event.stopPropagation()}
                onClick={event => {
                  event.stopPropagation();
                }} >
                <EmailIcon fontSize='small' color='primary' style={{ verticalAlign: "middle" }} />
                {' ' + student.oma_email}
              </Link>
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
