import React from 'react';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function WorkInstructorCard({ data: instructor, handleSelected }) {

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardActionArea onClick={() => handleSelected(instructor)}>
        <CardContent sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h5" component="div">
            {instructor.nimi}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {instructor.email}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {instructor.puhelin}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
