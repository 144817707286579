import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function SimpleTeacherPaper({ teacher }) {

  return (
    <Paper sx={{ pl: 1, pr: 1 }}>
      <Typography variant="h5">
        {teacher.first_name + ' ' + teacher.last_name}
      </Typography>
      <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
        {"Nimimerkki: "}
      </Typography>
      <Typography variant="body1" display="inline">
        {teacher.nick_name}
      </Typography>
      <Typography />
      {teacher.discord_id && (
        <>
          <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
            {"Discord: "}
          </Typography>
          <Typography variant="body1" display="inline">
            {teacher.discord_id}
          </Typography>
        </>
      )}
      <Typography />
      {teacher.puhelin && (
        <Typography variant="body1" display="inline">
          <Link variant='inherit' underline='hover'
            href={'tel:' + teacher.puhelin} >
            <PhoneIcon fontSize='small' color='primary' style={{ verticalAlign: "middle" }} />
            {' ' + teacher.puhelin}
          </Link>
        </Typography>
      )}
      <Typography />
      {teacher.email && (
        <Typography variant="body1" display="inline">
          <Link variant='inherit' underline='hover'
            href={'mailto:' + teacher.email} >
            <EmailIcon fontSize='small' color='primary' style={{ verticalAlign: "middle" }} />
            {' ' + teacher.email}
          </Link>
        </Typography>
      )}
    </Paper>
  )
}
