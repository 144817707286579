import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import axios from "axios"

import GeneralSearch from '../Searches/GeneralSearch'
import InfiScrollCardGrid from '../Grid/InfiScrollCardGrid'
import TeacherCard from 'components/Cards/Select/TeacherCard'

export default function SelectTeacherDialog({ open, setOpen, setTeacher }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(true)
  const [teachers, setTeachers] = useState([])
  const [teacherSearch, setTeacherSearch] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_API_BASE + '/opettaja/all')
      .then(res => {
        console.log("Opettajat", res.data)
        setTeachers(res.data.data)
        setTeacherSearch(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching teachers: ", e)
      })
  }, [])


  const handleClose = () => {
    setOpen(false);
  };


  const handleSelected = (teacher) => {
    setTeacher(teacher)
    handleClose()
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valitse opettaja"}
        </DialogTitle>
        <DialogContent id="teacher-scrollable">
          <Stack spacing={2} alignItems="center" mt={2}>
            <GeneralSearch
              data={teachers}
              search={setTeacherSearch}
              search_keys={["nick_name", "first_name", "last_name", "grp", "status_date", "status_text", "discord_id", "user.email"]}
              autocomplete_key={"nick_name"}
            />

            <InfiScrollCardGrid
              card={TeacherCard}
              data={teacherSearch}
              handleSelected={handleSelected}
              idKey='id'

              itemScroll={12}
              scrollableTarget="teacher-scrollable"
              scrollThreshold="100px"
            />
            {/* <StudentSearchGrid data={teacherSearch} setStudent={setTeacher} handleClose={handleClose} /> */}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
