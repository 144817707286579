
import React from 'react';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function DegreePartCard({ data: degreePart, handleSelected }) {

  return (
    <Card sx={{ maxWidth: 250 }}>
      <CardActionArea onClick={() => handleSelected(degreePart)}>
        <CardContent sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h5" component="div">
            {degreePart.to_nimi}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {degreePart.to_ammattitaitovaatimukset}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
