import React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea'
import Typography from '@mui/material/Typography';


export default function StudentCard({ data: student, handleSelected }) {

  return (
    <Card sx={{ minWidth: 200 }}>
      <CardActionArea onClick={() => handleSelected(student)}>
        <CardContent>
          <Typography variant="h5" component="div">
            {student.nick_name}
          </Typography>
          <Typography variant="body2">
            {student.first_name + ' ' + student.last_name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
