import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';


import CssBaseline from "@mui/material/CssBaseline";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

import NewWorkPeriodModal from 'components/modal/NewWorkPeriodModal'
import WorkPeriodTable from 'components/Tables/WorkPeriodTable'
// import Sidebar from "components/Sidebar/Sidebar";

import { NotificationContext } from 'context/SnackbarProvider.js'
import TeacherService from 'services/teacher.service'

export default function TopPaikka(props) {
  let { tp } = useParams()
  const { createNotification } = useContext(NotificationContext)

  const [company, setCompany] = useState([])
  const [loading, setLoading] = useState(true)
  const [createTopOpen, setCreateTopOpen] = useState(false);

  console.log(tp)
  useEffect(() => {
    TeacherService.getWorkplace(tp)
      .then(res => {
        console.log('Got data successfully')
        setCompany(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching company:", e)
      })
  }, []);

  const handleCreateTopOpen = () => {
    setCreateTopOpen(true);
  };


  const handleCreateTopClose = (created) => {
    if (created == true) {
      createNotification({ message: "Topjakso luotu!", status: "success" })
    }
    console.log("closed");
    setCreateTopOpen(false);
  };



  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <Fragment>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Toppaikka | Temp</title>
          </Helmet>
        </HelmetProvider>
        {/* <Sidebar ifUserIs={"opettaja"} /> */}

        <CssBaseline />
        <Container maxWidth="sm">
          <Box display="flex" justifyContent="center" mt={2}>
            <Stack spacing={2} alignItems="center" >
              <Typography variant="h5" component="div">
                {company.nimi}
              </Typography>
              <WorkPeriodTable workperiods={company.topjaksot} />
              <Button variant="contained" onClick={handleCreateTopOpen}>
                Lisää topjakso
              </Button>
            </Stack>
          </Box>
          <NewWorkPeriodModal
            handleOpen={handleCreateTopOpen}
            handleClose={handleCreateTopClose}
            open={createTopOpen}
            tp_id={tp}
          />
        </Container>
      </div>
    </Fragment>
  )



}
