import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import DatePicker from '@mui/lab/DatePicker'


import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { intervalToDuration, formatDuration } from 'date-fns'

import BaseEditor from '../Editor/BaseEditor'
import { NotificationContext } from '../../context/SnackbarProvider.js'
import StudentService from '../../services/student.service'

export default function AddWorklogDialog({ open, setOpen, refresh, datepicker }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { tj_id } = useParams()
  const { createNotification } = useContext(NotificationContext)

  const [startTime, setStartTime] = useState(new Date())
  const [stopTime, setStopTime] = useState(new Date())
  const [date, setDate] = useState(new Date())
  const [description, setDescription] = useState()

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose()
  }

  const handleSubmit = () => {
    let duration = intervalToDuration({
      start: startTime,
      end: stopTime
    })

    duration = formatDuration(duration,
      { format: ['hours', 'minutes'], zero: true })
    duration = duration
      .replace(/[^\d ]+/g, "")
      .replace('  ', ':')
      .trim() + ':00'

    let finalStart = startTime
    finalStart.setDate(date.getDate())
    let finalStop = stopTime
    finalStop.setDate(date.getDate())


    const worklog = {
      alku: finalStart,
      loppu: finalStop,
      kesto: duration,
      kuvaus: description
    }

    console.log(worklog)

    StudentService.postWorklog(tj_id, worklog)
      .then(res => {
        console.log('Success', res.data)
        createNotification({ message: "Tyoloki luotu", status: "success" })
        refresh()
        setOpen(false)
      })
      .catch(e => {
        createNotification({ message: "Tyolokin luomisessa tapahtui virhe!", status: 'error' })
        console.log('Error', e)
      })
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Lisää työloki"}
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack alignItems='center' spacing={1} pt={2}>
              {datepicker &&
                <DatePicker
                  disableFuture
                  label="Päivä"
                  openTo="day"
                  views={['month', 'day']}
                  value={date}
                  onChange={(newDate) => {
                    setDate(newDate)
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              }
              <Stack align='center' >
                <TimePicker
                  value={startTime}
                  onChange={setStartTime}
                  ampm={false}
                  renderInput={(params) => <TextField {...params} />}
                />
                <Typography variant="h6">
                  saakka
                </Typography>

                <TimePicker
                  value={stopTime}
                  onChange={setStopTime}
                  ampm={false}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Stack>
            </Stack>
          </LocalizationProvider>
          <Paper sx={{ mt: 2 }} >
            <BaseEditor editable="true"
              toolbar="true" content={description}
              output={setDescription} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={handleCancel}>
            Peruuta
          </Button>
          <Button variant="contained" onClick={handleSubmit}>Luo</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
