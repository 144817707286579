import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'
// import Input from '@mui/material/Input'
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import TopPaikkaForms from '../DataForms/TopPaikkaForms'
import TopJaksoTable from '../Tables/TopJaksoTable'
import WorkPeriodForms from '../Accordions/WorkPeriod/WorkPeriodAccordions.js'

// import { Context } from "../../context";
import axios from 'axios';
import WorkPeriodAccordions from '../Accordions/WorkPeriod/WorkPeriodAccordions.js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: ['100%', '80%'],
  height: ['100%', '80%'],
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'scroll'
};

const Input = styled('input')({
  display: 'none',
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


export default function CompanySettings({ handleOpen, handleClose, open, company }) {

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [topPaikkaData, setTopPaikkaData] = useState({
    nimi: null,
    esittely: null,
    yhteystiedot: null,
    '360linkki': null,
    yrityksenlogo: null,
    vaadittuosaaminen: null,
    korttikoulutukset: null,
    videoesittely: null,
    sijainti: null,
    paikkojenmaara: null
  })

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ bgcolor: 'background.paper', width: "100%" }}>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="Työpaikka" {...a11yProps(0)} />
                <Tab label="Topjakso" {...a11yProps(1)} />
                <Tab label="Item Three" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <TopPaikkaForms company={company} />
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <WorkPeriodAccordions company={company} />
              </TabPanel>
              <TabPanel value={value} index={2} dir={theme.direction}>
                Item Three
              </TabPanel>
            </SwipeableViews>
          </Box>


        </Box>
      </Modal>
    </div >
  );
}

