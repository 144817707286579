import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'

// import TopPaikkaForms from '../../DataForms/TopPaikkaForms'
import SimpleWorklogCard from 'components/Cards/SimpleWorklogCard'
import AddWorklogDialog from 'components/Dialogs/AddWorklogDialog'

import useAuth from 'context/useAuth'

export default function WorkPeriodWorklogAccordion({ workperiod, refresh }) {
  const [expanded, setExpanded] = useState(false);
  const [worklogDialogOpen, setWorklogDialogOpen] = useState(false)
  const { user } = useAuth()

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <>
      {workperiod
        ? <Accordion sx={{ width: "100%" }} expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={workperiod.tj_id + "-content"}
            id={workperiod.tj_id + "-panel"}
          >
            <Typography variant='h6'>
              Työlokit
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{
            pt: '16px', pl: { xs: 0, sm: '16px' },
            pr: { xs: 0, sm: '16px' }
          }} >
            <Grid container
              justifyContent='center'
              rowSpacing={1}
            >
              {user.userType === 'opiskelija' &&
                <Grid item align='center' xs={12}>
                  <Button
                    variant='contained'
                    onClick={() => setWorklogDialogOpen(true)}
                  >
                    Lisää Työloki</Button>
                </Grid>
              }
              {workperiod.tyolokit.map(worklog => (
                <Grid key={worklog.id} item xs={12} md={9} >
                  <SimpleWorklogCard data={worklog} refresh={refresh} />
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
          <AddWorklogDialog open={worklogDialogOpen} setOpen={setWorklogDialogOpen} datepicker={true} refresh={refresh} />
        </Accordion>
        : <Skeleton width={'100%'} height={56} />
      }
    </>
  );
}
