import React, { useState, useContext } from 'react';
import Stack from '@mui/material/Stack'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';

import { red, green, grey } from '@mui/material/colors';

import axios from 'axios'

import { useParams } from 'react-router-dom';
import { NotificationContext } from '../../context/SnackbarProvider.js'

export default function CompetenceList({ competences }) {

  const handleToggle = () => {
    console.log('helloworld')
  }

  return (
    <List sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper' }}>
      {competences.map((competence) => {
        return (
          <ListItem
            key={competence.oid}
            disablePadding
          >
            <ListItemButton role={undefined} onClick={() => handleToggle(competence)} dense>
              <ListItemText id={competence.oid} primary={competence.kuvaus} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}
