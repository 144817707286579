import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button'
import { Link as RouterLink } from 'react-router-dom';


export default function WorkPeriodAccordion({ workperiod }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (event, isExpanded) => {
    console.log(isExpanded)
    setExpanded(isExpanded);
  };

  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={workperiod.tj_id + "-content"}
        id={workperiod.tj_id + "-panel"}
        sx={{}}
      >
        <Typography sx={{ width: '15%', flexShrink: 0 }}>
          {workperiod.lisatieto}
        </Typography>
        <Typography sx={{ ml: 2, color: 'text.secondary' }}>
          {workperiod.alku_pvm}
        </Typography>
        <Typography sx={{ ml: 2, color: 'text.secondary' }}>
          {workperiod.loppu_pvm}
        </Typography>
        <Typography sx={{ ml: 2, color: 'text.secondary' }}>
          {workperiod.opiskelija.nick_name}
        </Typography>
        <Typography sx={{ color: 'text.secondary', mr: 3, ml: 2 }}>
          {workperiod.opettaja.nick_name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        Hello
        <Button component={RouterLink} to={'/topjaksot/all/' + workperiod.tj_id}>
          Avaa topjakso
        </Button>
      </AccordionDetails>
    </Accordion>
  );
}
