import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import axios from 'axios'
import StudentService from '../../services/student.service'

import CssBaseline from "@mui/material/CssBaseline";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

// import Sidebar from "../../components/Sidebar/Sidebar";

import InstructorStudentsAccordion from '../../components/Accordions/Instructor/InstructorStudentsAccordion.js'
import SimpleStudentCard from "../../components/Cards/SimpleStudentCard";
import SimpleWorkPeriodCard from "../../components/Cards/SimpleWorkPeriodCard"

import { NotificationContext } from '../../context/SnackbarProvider.js'
// import useAuth from '../../context/useAuth'

export default function WorkPeriods(props) {
  // const { user } = useAuth()
  const { createNotification } = useContext(NotificationContext)
  const navigate = useNavigate()

  const [selfData, setSelfData] = useState([])
  const [workperiods, setWorkperiods] = useState([])
  const [loading, setLoading] = useState(true)

  // console.log(uid)
  useEffect(() => {
    StudentService.getWorkperiods()
      .then(res => {
        console.log('Got data successfully')
        console.log(res.data.data.topjaksot)
        setWorkperiods(res.data.data.topjaksot)
        setSelfData(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching work periods:", e)
      })
  }, []);

  // const handleCreateTopOpen = () => {
  //   setCreateTopOpen(true);
  // };


  // const handleCreateTopClose = (created) => {
  //   if (created == true) {
  //     createNotification({ message: "Topjakso luotu!", status: "success" })
  //   }
  //   console.log("closed");
  //   setCreateTopOpen(false);
  // };

  const handleStudentSelected = () => {
    console.log("no")
    // navigate('./oppilaat/' + student.uid)
  }


  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Ohjaaja | Etusivu</title>
        </Helmet>
      </HelmetProvider>
      {/* <Sidebar ifUserIs={"opettaja"} /> */}

      <CssBaseline />
      <Container maxWidth="lg">
        <Box justifyContent="center" mt={2}>
          <Stack spacing={2} alignItems="center" >
            <Typography variant="h5" component="div">
              {loading
                ? <Skeleton width={100} />
                : selfData.nick_name
              }
            </Typography>
            {/* <InstructorStudentsAccordion students={selfData.opiskelijat} /> */}
            <Typography variant="h4" align='center'>
              {loading
                ? <Skeleton width={400} height={60} />
                : "Työpaikka Harjoittelujaksoni"
              }
            </Typography>
            <Grid container justifyContent="center"
              spacing={2}
            >
              {loading
                ? Array(3).fill().map((_, i) => (
                  <Grid item key={i} xs={12} sm={6} md={4} align='center'>
                    <Skeleton sx={{ height: '34vh', mb: -9 }} />
                  </Grid>
                ))
                : selfData.topjaksot.map((workperiod) => (
                  <Grid item key={workperiod.tj_id} xs={12} sm={6} md={4} align='center'>

                    <SimpleWorkPeriodCard data={workperiod} handleSelected={() => navigate('/topjaksot/' + workperiod.tj_id)} />
                    {/* <SimpleStudentCard data={student} handleSelected={() => navigate('./oppilaat/' + student.uid)} /> */}
                  </Grid>
                ))
              }

            </Grid>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )



}
