import React, { useState, useContext } from 'react';

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

export default function ProfileSettingsForms({ user, settings, setSettings }) {

  console.log('Ayo', settings && settings.nick_name)

  const handleChange = (event) => {

    console.log(event.target.value)
    setSettings(prev => ({ ...prev, [event.target.id]: event.target.value }))

  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container rowSpacing={2} columnSpacing={2} >
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="nick_name"
            label="Nimimerkki"
            value={settings && settings.nick_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="discord_id"
            label="Discord ID"
            value={settings && settings.discord_id}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="oma_email"
            label="Oma sähköposti"
            value={settings && settings.oma_email}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="puhelin"
            label="Puhelin"
            value={settings && settings.puhelin}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant='contained'> Tallenna </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}


