import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_BASE + '/ohjaaja/';

class InstructorService {
  getSelf() {
    return axios.get(API_URL, { headers: authHeader() })
  }

  getStudent(uid) {
    return axios.get(API_URL + 'opiskelijat/' + uid, { headers: authHeader() })
  }
}
export default new InstructorService();
