import { Outlet } from 'react-router-dom';
import Sidebar from '../src/components/Sidebar/Sidebar';
import Grid from '@mui/material/Grid'

export const Layout = () => (
  <Grid container>
    <Grid item md={4}>
      <Sidebar />
    </Grid>
    <Grid item md={8}>
      <Outlet />
    </Grid>
  </Grid>
);

