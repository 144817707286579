
import React, { useState } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";


import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'

import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const Osaaminen = styled(Paper)(({ theme, key }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));

export default function WorktaskCard({ data: task, handleSelected }) {

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardActionArea onClick={() => handleSelected(task)}>
        <CardContent sx={{ width: '100%' }}>
          <Typography gutterBottom variant="h5" component="div">
            {task.nimike}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {task.kuvaus}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="Osaamiset"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Stack spacing={1}>
            {task.osaamiset.map((osaaminen) => (
              <Osaaminen key={"osaaminen-" + osaaminen.id} elevation={3}>
                {osaaminen.kuvaus}
                {osaaminen.meta}
              </Osaaminen>
            ))}
          </Stack>
        </CardContent>
      </Collapse>
    </Card>
  );
}
