import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import axios from "axios"

import GeneralSearch from '../Searches/GeneralSearch'
import InfiScrollCardGrid from '../Grid/InfiScrollCardGrid'
import InstructorCard from 'components/Cards/Select/InstructorCard'
import NewInstructorModal from '../modal/NewInstructorModal'


import { NotificationContext } from '../../context/SnackbarProvider.js'

export default function SelectWorkInstructorDialog({ open, setOpen, instructors, setInstructors, tp }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { createNotification } = useContext(NotificationContext)

  const [loading, setLoading] = useState(true)
  const [unselectedWorkInstructors, setUnselectedWorkInstructors] = useState([])
  const [workInstructorSearch, setWorkInstructorSearch] = useState([])
  const [createInstructorOpen, setCreateInstructorOpen] = useState(false)

  useEffect(() => {
    console.log(tp)
    axios(process.env.REACT_APP_API_BASE + `/toppaikat/${tp}/ohjaajat/`)
      .then(res => {
        console.log("Work instructors", res.data)
        setUnselectedWorkInstructors(res.data.data)
        setWorkInstructorSearch(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching instructors: ", e)
      })
  }, [loading])


  const handleCreateInstructorOpen = () => {
    setCreateInstructorOpen(true);
  };


  const handleCreateInstructorClose = (created) => {
    if (created == true) {
      createNotification({ message: "Topohjaaja luotu!", status: "success" })
    }
    setCreateInstructorOpen(false)
    setLoading(true)
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelected = (instructor) => {
    let wt = instructors
    wt.push(instructor)
    setInstructors(wt)
    let unsel = []

    const ids = wt.map(({ id }) => id)
    unselectedWorkInstructors.forEach(x => {
      if (!ids.includes(x.id)) {
        unsel.push(x)
      }
    })

    setUnselectedWorkInstructors(unsel)
    setWorkInstructorSearch(unsel)
    console.log(instructors)
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valitse top ohjaajat"}
        </DialogTitle>
        <DialogContent id="instructor-scrollable">
          <Stack spacing={2} alignItems="center" mt={2}>
            <Box display="flex" justifyContent="center" mt={2}>
              <Stack spacing={2} direction="row" alignItems="center" >
                <GeneralSearch
                  data={unselectedWorkInstructors}
                  search={setWorkInstructorSearch}
                  search_keys={['nimi', 'puhelin']}
                  autocomplete_key={'nimi'}
                />
                <Button variant="contained" onClick={handleCreateInstructorOpen}>
                  Lisää topohjaaja
                </Button>
              </Stack>
            </Box>
            <InfiScrollCardGrid
              card={InstructorCard}
              data={workInstructorSearch}
              handleSelected={handleSelected}
              idKey='id'

              itemScroll={12}
              scrollableTarget="instructor-scrollable"
              scrollThreshold="100px"
            />
            <NewInstructorModal
              handleOpen={handleCreateInstructorOpen}
              handleClose={handleCreateInstructorClose}
              open={createInstructorOpen}
              tp_id={tp}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
