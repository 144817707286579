import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { useNavigate } from "react-router-dom";
const uid = 23
const top_id = 2


export const studentItems = [
  {
    name: 'Topjaksot',
    path: `/topjaksot/`,
    Icon: WorkIcon
  },
  {
    name: 'Profiili',
    path: '/profiili/',
    Icon: AccountBoxIcon
  }
]

export const teacherItems = [
  {
    name: 'Etusivu',
    path: '/',
    Icon: HomeIcon
  },
  {
    name: 'Toppaikat',
    path: `/toppaikat/all/`,
    Icon: CorporateFareIcon
  },
  {
    name: 'Profiili',
    path: '/profiili/',
    Icon: AccountBoxIcon
  }

]

export const instructorItems = [
  {
    name: 'Opiskelijani',
    path: `/`,
    Icon: PeopleOutlineIcon
  },
  {
    name: 'Profiili',
    path: '/profiili/',
    Icon: AccountBoxIcon
  }
]
