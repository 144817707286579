import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_BASE + '/opiskelija/';

class StudentService {
  getSelf() {
    return axios.get(API_URL, { headers: authHeader() })
  }

  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getWorkperiods() {
    return axios.get(API_URL + 'topjaksot', { headers: authHeader() });
  }

  getWorkperiod(tj_id) {
    return axios.get(API_URL + 'topjaksot/' + tj_id, { headers: authHeader() });
  }

  getWorktasks(tj_id) {
    return axios.get(API_URL + 'topjaksot/' + tj_id
      + '/tyotehtavat/', { headers: authHeader() });
  }

  postWorklog(tj_id, worklog) {
    return axios.post(API_URL + 'topjaksot/' + tj_id + '/tyolokit/', worklog, { headers: authHeader() })
  }

}

export default new StudentService();
