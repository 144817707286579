import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import axios from "axios"

import GeneralSearch from '../Searches/GeneralSearch'
import InfiScrollCardGrid from '../Grid/InfiScrollCardGrid'
import WorktaskCard from 'components/Cards/Select/WorktaskCard';

export default function SelectWorkTasksDialog({ open, setOpen, worktasks, setWorktasks }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(true)
  const [unselectedWorktasks, setUnselectedWorktasks] = useState([])
  const [worktaskSearch, setWorktaskSearch] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_API_BASE + '/tyotehtavat/')
      .then(res => {
        console.log("Tyotehtavat", res.data)
        setUnselectedWorktasks(res.data)
        setWorktaskSearch(res.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching worktasks: ", e)
      })
  }, [])


  const handleClose = () => {
    setOpen(false);
  };

  const handleSelected = (task) => {
    let wt = worktasks
    wt.push(task)
    setWorktasks(wt)
    let unsel = []

    const ids = wt.map(({ id }) => id)
    unselectedWorktasks.forEach(x => {
      if (!ids.includes(x.id)) {
        unsel.push(x)
      }
    })

    setUnselectedWorktasks(unsel)
    setWorktaskSearch(unsel)
    console.log(worktasks)
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valitse työtehtavat"}
        </DialogTitle>
        <DialogContent id="worktasks-scrollable">
          <Stack spacing={2} alignItems="center" mt={2}>
            <GeneralSearch
              data={unselectedWorktasks}
              search={setWorktaskSearch}
              search_keys={['nimike', 'kuvaus']}
              autocomplete_key={'nimike'}
            />
            {/* <WorktaskCardGrid worktaskSearch={worktaskSearch} handleSelected={handleSelected} /> */}
            <InfiScrollCardGrid
              card={WorktaskCard}
              data={worktaskSearch}
              handleSelected={handleSelected}
              idKey='id'

              itemScroll={12}
              scrollableTarget="worktasks-scrollable"
              scrollThreshold="100px"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
