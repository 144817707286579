import axios from 'axios';
import authHeader from './auth-header';

const API_SELF_URL = process.env.REACT_APP_API_BASE + '/opettaja/';
const API_WORKPLACE_URL = process.env.REACT_APP_API_BASE + '/toppaikat/';
const API_WORKPERIOD_URL = process.env.REACT_APP_API_BASE + '/topjaksot/';

class TeacherService {
  getSelf() {
    return axios.get(API_SELF_URL, { headers: authHeader() })
  }
  getWorkplaces() {
    return axios.get(API_WORKPLACE_URL, { headers: authHeader() })
  }
  getWorkplace(tp) {
    return axios.get(API_WORKPLACE_URL + tp, { headers: authHeader() })
  }

  getWorkperiods() {
    return axios.get(API_WORKPERIOD_URL + '/', { headers: authHeader() })
  }
  getWorkperiod(tj_id) {
    return axios.get(API_WORKPERIOD_URL + '/' + tj_id, { headers: authHeader() })
  }
}
export default new TeacherService();
