import * as React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function NotFound() {

  return (
    <Paper>
      <Grid container
        height={'100vh'}
        justifyContent='center'
        alignItems='center'
      >
        <Grid item>
          <Typography variant='h2'>
            Not found.
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}
