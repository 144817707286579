import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';

import axios from "axios";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

// import Sidebar from "../../components/Sidebar/Sidebar";

import TeacherAccordion from '../../components/Accordions/Teacher/TeacherAccordion'

import TeacherService from '../../services/teacher.service'


import { NotificationContext } from '../../context/SnackbarProvider.js'

export default function InstructorFrontpage(props) {
  let { uid } = useParams()
  const { createNotification } = useContext(NotificationContext)

  const [loading, setLoading] = useState(true)
  const [createTopOpen, setCreateTopOpen] = useState(false);
  const [selfData, setSelfData] = useState([])

  console.log(uid)
  useEffect(() => {
    TeacherService.getSelf()
      .then(res => {
        console.log('Got data successfully')
        console.log(res.data.data)
        setSelfData(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching company:", e)
      })
  }, []);

  const handleCreateTopOpen = () => {
    setCreateTopOpen(true);
  };


  const handleCreateTopClose = (created) => {
    if (created == true) {
      createNotification({ message: "Topjakso luotu!", status: "success" })
    }
    console.log("closed");
    setCreateTopOpen(false);
  };



  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Opettaja | Etusivu</title>
        </Helmet>
      </HelmetProvider>
      {/* <Sidebar ifUserIs={"opettaja"} /> */}

      <CssBaseline />
      <Container maxWidth="sm">
        <Box justifyContent="center" mt={2}>
          <Stack spacing={2} alignItems="center" >
            <Typography variant="h5" component="div">
              {selfData.opettaja.nick_name}

            </Typography>
            <TeacherAccordion />
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )



}
