import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from '../services/auth.service'

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState();
  const [themeMode, setThemeMode] = useState('light');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  useEffect(() => {
    AuthService.getCurrentUser()
      .then((user) => setUser(user))
      .catch((_error) => { })
      .finally(() => setLoadingInitial(false));
    const localTheme = localStorage.getItem('themeMode')
    if (localTheme === 'dark') {
      setThemeMode('dark')
    } else if (localTheme === 'light') {
      setThemeMode('light')
    }
  }, []);

  function login(email, password) {
    setLoading(true);

    AuthService.login(email, password)
      .then((user) => {
        setUser(user);
        navigate('/')
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }

  // function loginTop(toppass) {
  //   setLoading(true);

  //   AuthService.login(email, password)
  //     .then((user) => {
  //       setUser(user);
  //       navigate('/')
  //     })
  //     .catch((error) => setError(error))
  //     .finally(() => setLoading(false));
  // }

  // function signUp(email, name, password) {
  //   setLoading(true);

  //   usersApi.signUp({ email, name, password })
  //     .then((user) => {
  //       setUser(user);
  //       navigate.push("/");
  //     })
  //     .catch((error) => setError(error))
  //     .finally(() => setLoading(false));
  // }

  function logout() {
    AuthService.logout().then(() => setUser(undefined));
  }

  function setTheme(theme) {
    if (theme === 'light') {
      setTheme('light')
    } else if (theme === 'dark') {
      setTheme('dark')
    }
  }

  const memoedValue = useMemo(
    () => ({
      user,
      themeMode,
      setThemeMode,
      loading,
      error,
      login,
      logout,
    }),
    [user, themeMode, loading, error]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
}

// Let's only export the `useAuth` hook instead of the context.
// We only want to use the hook directly and never the context component.
export default function useAuth() {
  return useContext(AuthContext);
}
