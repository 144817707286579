import React, { useState } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

// For theme switch
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import { Outlet } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';

import logo from "assets/images/it-velhot.png";
import { studentItems, teacherItems, instructorItems } from './SidebarData'
import { useNavigate } from "react-router-dom";
import AuthService from 'services/auth.service'
import useAuth from "context/useAuth";

const drawerWidth = 240;



// const theme = createTheme({
// });


const ThemeSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(22px)',
      '& .MuiSwitch-thumb:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff',
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
    width: 32,
    height: 32,
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        '#fff',
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
    borderRadius: 20 / 2,
  },
}));


export default function Sidebar(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  // const [themeSetting, setThemeSetting] = useState('light')

  const { user, themeMode, setThemeMode } = useAuth()

  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (path) => {
    navigate(path)
    if (mobileOpen) {
      setMobileOpen(!mobileOpen)
    }

  }

  const changeTheme = () => {
    localStorage.setItem('themeMode', themeMode === 'dark' ? 'light' : 'dark')
    setThemeMode(themeMode === 'dark' ? 'light' : 'dark')
  }

  const handleLogout = () => {
    AuthService.logout()
    navigate('/login')
  }

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode === 'dark' ? 'dark' : 'light',
          primary: {
            main: '#8dc63f'
          },
        },
      }),
    [themeMode],
  );

  const drawer = (
    <>
      <Toolbar />
      <Divider />
      {/* <List> */}
      {/*   {['Etusivu', 'Projektit', 'Profiili'].map((text, index) => ( */}
      {/*     <ListItem button key={text}> */}
      {/*       <ListItemIcon> */}
      {/*         {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
      {/*       </ListItemIcon> */}
      {/*       <ListItemText primary={text} /> */}
      {/*     </ListItem> */}
      {/*   ))} */}
      {/* </List> */}
      {/* <Divider /> */}
      <List>
        {user.userType == 'opiskelija' && studentItems.map(({ name, path, Icon }) => (
          <ListItem button onClick={() => handleClick(path)} key={name}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
        {user.userType == 'opettaja' && teacherItems.map(({ name, path, Icon }) => (
          <ListItem button onClick={() => handleClick(path)} key={name}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
        {user.userType == 'topohjaaja' && instructorItems.map(({ name, path, Icon }) => (
          <ListItem button onClick={() => handleClick(path)} key={name}>
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={'Kirjaudu Ulos'} />
        </ListItem>
      </List>

      <List style={{ marginTop: `auto` }} >
        <ListItem>
          <FormGroup>
            <FormControlLabel
              control={<ThemeSwitch sx={{ m: 1 }} checked={themeMode === 'dark'} onClick={changeTheme} />}
              label={themeMode === 'dark' ? 'Musta teema' : 'Valkoinen teema'}
            />
          </FormGroup>
        </ListItem>
      </List>

    </>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>

            <img
              style={{ ml: 1, marginRight: 20 }}
              src={logo}
              width="%"
              height="50"
              alt="Ossi Logo"
            />
            <Typography variant="h6" noWrap component="div">
              Ossi
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: { xs: "6px", sm: "16px" }, pb: 2, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
    </ThemeProvider>
  );
}




