import React, { useState, useContext, useEffect } from 'react';
import Stack from '@mui/material/Stack'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'

import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography'

import SchoolIcon from '@mui/icons-material/School';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { red, green, grey } from '@mui/material/colors';

import GeneralService from 'services/general.service'

import useAuth from 'context/useAuth'
import { useParams } from 'react-router-dom';
import { NotificationContext } from '../../context/SnackbarProvider.js'

export default function CompetenceControlListStars({ competences }) {
  const [checked, setChecked] = useState(() => {
    return competences.map(({ suoritettu }) => suoritettu)
      .filter(v => v.length > 0 && v[0].toposaa != null)
      .map((v) => v[0].oid)
  });

  let { uid } = useParams()
  const { user } = useAuth()

  const [studentRating, setStudentRating] = useState(() => {
    return competences.map(({ suoritettu }) => suoritettu[0])
      .filter(v => v)
      .reduce((acc, value) => {
        acc[value.oid] = value.osaan
        return acc
      }, {})
  });
  const [instructorRating, setInstructorRating] = useState(() => {
    return competences.map(({ suoritettu }) => suoritettu[0])
      .filter(v => v)
      .reduce((acc, value) => {
        acc[value.oid] = value.toposaa
        return acc
      }, {})
  });

  const { createNotification } = useContext(NotificationContext)


  useEffect(() => {
    // let hi = instructorRating.find((r) => r.oid == 20)
    // console.log('Ayy', hi)
  }, [])

  const updateCompetenceState = (competence, state) => {

    let completionUid = null
    if (competence.suoritettu[0]) {
      completionUid = competence.suoritettu[0].uid
    } else if (user.userType === 'topohjaaja') {
      completionUid = uid
    }

    console.log('Au', competence, completionUid)
    if (completionUid) {
      console.log('COMP IS', competence, state)
      GeneralService.updateCompetenceCompletion(competence.oid, completionUid, state)
        .then(res => {
          console.log("Response", res.data)

          if (user.userType == 'opiskelija') {
            setStudentRating((prevState) => {
              return { ...prevState, [competence.oid]: state }
            })
          } else if (user.userType == 'topohjaaja') {
            if (state === 0) {
              setChecked(prev => [...prev, competence.oid])
            } else if (state === null) {
              console.log('hello')
              setChecked(checked.filter((i) => i !== competence.oid))
            }
            setInstructorRating((prevState) => {
              return { ...prevState, [competence.oid]: state }
            })
          }

          createNotification({ message: "Muutos tallennettu", status: "success" })
        })
        .catch(e => {
          console.log("Error in updating state:", e)
          createNotification({ message: "Muutoksen tallennuksessa oli virhe", status: "error" })
        })

    }
  }

  const handleToggle = (competence, e) => {
    if (user.userType === 'topohjaaja') {
      if (checked.indexOf(competence.oid) !== -1) {
        updateCompetenceState(competence, null)
      } else (
        updateCompetenceState(competence, 0)
      )
    }
  }

  return (
    <List sx={{ width: '100%', maxHeight: 300, bgcolor: 'background.paper' }}>
      {competences.map((competence) => {
        const labelId = `checkbox-list-label-${competence.oid}`;
        const disabled = checked.indexOf(competence.oid) == -1
        const color = disabled ? green : red

        return (
          <ListItem
            key={competence.oid}
            disablePadding
          >
            <ListItemButton sx={{
              "&.MuiButtonBase-root": {
                pointerEvents: user.userType === 'topohjaaja' ? 'auto' : 'none'
              }
            }}
              role={undefined} onClick={(e) => handleToggle(competence, e)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(competence.oid) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={competence.kuvaus}
                sx={{ pr: 8, color: disabled ? grey[500] : null }}
              />
            </ListItemButton>

            <ListItemSecondaryAction style={{ whiteSpace: 'normal', display: 'block' }}>
              <Box style={{ whiteSpace: 'normal' }}>
                <Stack direction="column">
                  <Stack direction='row' spacing={1}>
                    <SchoolIcon fontSize='small' color='primary' />
                    <Rating
                      disabled={disabled || user.userType == 'topohjaaja'}
                      size='small'
                      name="student-evaluation"
                      value={studentRating[competence.oid]}
                      onChange={(event, newValue) => {
                        updateCompetenceState(competence, newValue)
                      }}
                      readOnly={user.userType == 'topohjaaja'}
                    />
                  </Stack>
                  <Stack direction='row' spacing={1}>
                    <SupervisorAccountIcon fontSize='small' color='secondary' />
                    <Rating
                      disabled={disabled || user.userType == 'opiskelija'}
                      size='small'
                      name="instructor-evaluation"
                      value={instructorRating[competence.oid]}
                      onChange={(event, newValue) => {
                        updateCompetenceState(competence, newValue)
                      }}
                      readOnly={user.userType == 'opiskelija'}
                    />
                  </Stack>
                </Stack>
              </Box>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  );
}
