import React, { useState, useEffect, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper'

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import useAuth from 'context/useAuth'
import GeneralService from 'services/general.service'


import BaseEditor from 'components/Editor/BaseEditor'
import { NotificationContext } from 'context/SnackbarProvider.js'

export default function AddPlanCommentDialog({ open, setOpen, tj_id, refresh }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { user } = useAuth()
  const { createNotification } = useContext(NotificationContext)

  const [comment, setComment] = useState()

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose()
  }

  const handleSubmit = () => {

    const finalComment = { kommentti: comment }

    GeneralService.addPlanComment(user, tj_id, finalComment)
      .then(res => {
        console.log('Success', res.data)
        createNotification({ message: "Kommentti lisätty", status: "success" })
        setOpen(false)
        refresh()
      })
      .catch(e => {
        console.log('Error', e)
      })
  }


  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Lisää kommentti"}
        </DialogTitle>
        <DialogContent>
          <Paper sx={{ mt: 2 }} >
            <BaseEditor editable="true"
              toolbar="true" content={comment}
              output={setComment} />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button autoFocus variant="contained" onClick={handleCancel}>
            Peruuta
          </Button>
          <Button variant="contained" onClick={handleSubmit}>Kommentoi</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
