import React from 'react';


import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';


export default function CompetenceCard({ data: competence, handleSelected }) {

  return (
    <Card sx={{ minWidth: 200, maxWidth: 200 }}>
      <CardActionArea onClick={() => handleSelected(competence)}>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {competence.kuvaus}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {competence.meta}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
