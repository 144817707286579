
import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';

import axios from "axios";

import CssBaseline from "@mui/material/CssBaseline";
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'

import ISCompetenceAccordion from 'components/Accordions/Instructor/ISCompetenceAccordion'
import WorkPeriodPlanAccordion from 'components/Accordions/WorkPeriod/WorkPeriodPlanAccordion'

import WorkperiodSummary from 'components/Papers/WorkperiodSummary'
import WorkPeriodWorklogAccordion from 'components/Accordions/WorkPeriod/WorkPeriodWorklogAccordion'

import TeacherService from 'services/teacher.service'
import useAuth from "context/useAuth";

export default function Workperiod(props) {
  let { tj_id } = useParams()
  const { user } = useAuth()
  const [workperiod, setWorkperiod] = useState()
  const [loading, setLoading] = useState(true)
  const [refresh, setRefresh] = useState(false)

  console.log(tj_id)
  useEffect(async () => {
    TeacherService.getWorkperiod(tj_id)
      .then(res => {
        console.log('Got data successfully')
        console.log('Xlb', res.data.data)
        setWorkperiod(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching workperiod:", e)
      })
  }, [refresh]);

  const handleRefresh = () => {
    console.log('hello refresh')
    setRefresh(!refresh)
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Topjakso | Temp</title>
        </Helmet>
      </HelmetProvider>
      {/* <Sidebar ifUserIs={"opettaja"} /> */}

      <CssBaseline />
      <Container maxWidth="md" disableGutters={true}>
        <Box width="100%" justifyContent="center" mt={2}>
          <Stack spacing={2} alignItems="center" >
            <WorkperiodSummary workperiod={workperiod} refresh={handleRefresh} />
            <WorkPeriodPlanAccordion workperiod={workperiod} refresh={handleRefresh} />
            <ISCompetenceAccordion workperiod={workperiod} refresh={handleRefresh} />
            <WorkPeriodWorklogAccordion workperiod={workperiod} refresh={handleRefresh} />
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )



}
