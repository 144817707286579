import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import StudentService from '../../services/student.service'

import CssBaseline from "@mui/material/CssBaseline";
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import { NotificationContext } from 'context/SnackbarProvider.js'

import ProfileSettingsForms from 'components/DataForms/students/ProfileSettingsForms'

// import useAuth from '../../context/useAuth'

export default function ProfileSettings(props) {
  // const { user } = useAuth()

  const { createNotification } = useContext(NotificationContext)
  const [selfData, setSelfData] = useState('')

  useEffect(() => {
    StudentService.getSelf()
      .then(res => {
        console.log('Got data successfully')
        console.log(res.data.data)
        setSelfData(res.data.data.opiskelija[0])
      })
      .catch(e => {
        console.log("Error fetching self:", e)
      })
  }, []);

  const handleStudentSelected = () => {
    console.log("no")
  }


  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Ohjaaja | Profiili</title>
        </Helmet>
      </HelmetProvider>

      <CssBaseline />
      <Container align='center'>
        <Grid container maxWidth={{ xs: 'xs', md: 'sm' }} justifyContent='center' spacing={4} >
          <Grid item xs={12}>
            <Typography variant='h4'>
              Asetukset
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ProfileSettingsForms settings={selfData} setSettings={setSelfData} />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )



}
