
import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import EditWorkPeriodPlanDialog from 'components/Dialogs/EditWorkPeriodPlanDialog'
import AddPlanCommentDialog from 'components/Dialogs/AddPlanCommentDialog'
import BaseEditor from 'components/Editor/BaseEditor';

import useAuth from 'context/useAuth'
import CommentCard from 'components/Cards/CommentCard';

export default function WorkPeriodPlanAccordion({ workperiod, refresh }) {
  const [expanded, setExpanded] = useState(false);
  const [plan, setPlan] = useState()
  const [editPlanOpen, setEditPlanOpen] = useState(false)
  const [addCommentOpen, setAddCommentOpen] = useState(false)

  const { user } = useAuth()

  useEffect(() => {
    workperiod && setPlan(workperiod.suunnittelu)
  }, [workperiod])

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  const handleEditPlanOpen = () => {
    setEditPlanOpen(true)
  }

  const handleEditPlanClose = () => {
    setEditPlanOpen(false)
  }

  return (
    <>
      {
        workperiod
          ? <Accordion sx={{ width: "100%" }}
            expanded={expanded} onChange={handleChange} >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={workperiod.tj_id + "-content"}
              id={workperiod.tj_id + "-panel"}
            >
              <Typography variant="h6">
                Näytön Suunnittelu
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ mt: 2, width: '100%' }}>
              <Stack alignItems='center' display='flex' width={'100%'} spacing={2}>
                {plan && <Paper sx={{ width: '100%' }} elevation={3}>
                  <BaseEditor content={plan} />
                </Paper>}
                {plan
                  ?
                  user.userType === 'opettaja' &&
                  <Button
                    variant="contained"
                    onClick={handleEditPlanOpen}>
                    Muokkaa sunnittelua
                  </Button>
                  :
                  user.userType === 'opettaja' &&
                  <Button
                    variant="contained"
                    onClick={handleEditPlanOpen}>
                    Aloita Suunnittelu
                  </Button>
                }
                {plan &&
                  <>
                    <Typography style={{ fontWeight: 600, display: 'block' }} variant='caption'>
                      {"Kommentit"}
                    </Typography>
                    <Stack width='100%' spacing={1} >
                      {
                        workperiod.kommentit &&
                        workperiod.kommentit.map((comment) => (
                          <CommentCard key={comment.id} data={comment} refresh={refresh} />
                        ))}
                      <Button
                        variant='contained'
                        onClick={() => setAddCommentOpen(!addCommentOpen)} >
                        Lisää kommentti
                      </Button>
                    </Stack>
                    <AddPlanCommentDialog
                      open={addCommentOpen}
                      setOpen={setAddCommentOpen}
                      refresh={refresh}
                      tj_id={workperiod.tj_id}
                    />
                  </>
                }
              </Stack>
              <EditWorkPeriodPlanDialog
                handleOpen={handleEditPlanOpen}
                handleClose={handleEditPlanClose}
                open={editPlanOpen}
                plan={plan}
                setPlan={setPlan}
                tj_id={workperiod.tj_id}
              />
            </AccordionDetails>
          </Accordion >
          : <Skeleton width={'100%'} height={56} />
      }
    </>
  );
}
