import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

import CssBaseline from "@mui/material/CssBaseline";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'

// import Sidebar from "components/Sidebar/Sidebar";

import InstructorStudentsAccordion from 'components/Accordions/Instructor/InstructorStudentsAccordion.js'
import SimpleStudentCard from "components/Cards/SimpleStudentCard";

import { NotificationContext } from 'context/SnackbarProvider.js'
import useAuth from 'context/useAuth'
import InstructorService from 'services/instructor.service'

export default function InstructorFrontpage(props) {
  const { createNotification } = useContext(NotificationContext)
  const navigate = useNavigate()

  const [company, setCompany] = useState([])
  const [loading, setLoading] = useState(true)
  const [createTopOpen, setCreateTopOpen] = useState(false);
  const [selfData, setSelfData] = useState([])

  const { user } = useAuth()

  useEffect(() => {
    InstructorService.getSelf()
      .then(res => {
        console.log('Got data successfully')
        console.log(res.data.data.top_paikat)
        setCompany(res.data.data.top_paikat)
        setSelfData(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching company:", e)
      })
  }, []);

  const handleCreateTopOpen = () => {
    setCreateTopOpen(true);
  };


  const handleCreateTopClose = (created) => {
    if (created == true) {
      createNotification({ message: "Topjakso luotu!", status: "success" })
    }
    console.log("closed");
    setCreateTopOpen(false);
  };

  const handleStudentSelected = () => {
    console.log("no")
    // navigate('./oppilaat/' + student.uid)
  }


  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Ohjaaja | Etusivu</title>
        </Helmet>
      </HelmetProvider>
      {/* <Sidebar ifUserIs={"opettaja"} /> */}

      <CssBaseline />
      <Container maxWidth="lg">
        <Box justifyContent="center" mt={2}>
          <Stack spacing={2} alignItems="center" >
            <Typography variant="h5" component="div">
              {loading
                ? <Skeleton width={100} />
                : selfData.nimi
              }
            </Typography>
            {/* <InstructorStudentsAccordion students={selfData.opiskelijat} /> */}
            <Typography variant="h4">
              Opiskelijani
            </Typography>
            <Grid container justifyContent="center" spacing={2}>
              {loading
                ? Array(3).fill().map((_, i) => (
                  <Grid item key={i} xs={12} sm={6} align='center'>
                    <Skeleton sx={{ height: '22vh', mb: -7 }} />
                  </Grid>
                ))
                : selfData.opiskelijat.map((student) => (
                  <Grid item key={student.uid} xs={12} sm={6} align='center'>
                    <SimpleStudentCard data={student} handleSelected={() => navigate('./oppilaat/' + student.uid)} />
                  </Grid>
                ))
              }
            </Grid>
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )



}
