
import React, { Fragment, Component, useState, useEffect } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";
import axios from 'axios'


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';


import CompanySettings from 'components/modal/CompanySettings'


export default function CompanyCard({ company }) {

  const [settingsOpen, setSettingsOpen] = useState(false)

  const handleSettingsOpen = () => {
    setSettingsOpen(true)
  }

  const handleSettingsClose = () => {
    setSettingsOpen(false)
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {company.nimi}
        </Typography>
        <Typography variant="body2">
          {company.esittely}
        </Typography>
      </CardContent>
      <CardActions>
        <Button component={RouterLink} to={'/toppaikat/all/' + company.tp_id} size="small">Company</Button>
        <Button onClick={handleSettingsOpen} size="small">Settings</Button>
      </CardActions>

      <CompanySettings handleOpen={handleSettingsOpen} handleClose={handleSettingsClose} open={settingsOpen} company={company} />
    </Card>
  );
}
