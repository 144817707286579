import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import axios from "axios";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'

// import InstructorAccordion from '../Instructor'
import InfiScrollCardGrid from '../../Grid/InfiScrollCardGrid'
import TeacherCard from 'components/Cards/Select/TeacherCard'
import GeneralSearch from '../../Searches/GeneralSearch'
import NewTeacherModal from '../../modal/NewTeacherModal'

export default function TeacherAccordion({ data }) {
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = useState(false)
  const [teachers, setTeachers] = useState([])
  const [teacherSearch, setTeacherSearch] = useState([])
  const [createTeacherOpen, setCreateTeacherOpen] = useState(false)

  useEffect(() => {
    axios(process.env.REACT_APP_API_BASE + '/opettaja/all')
      .then(res => {
        console.log('Got data successfully')
        console.log(res.data.data)
        setTeachers(res.data.data)
        setTeacherSearch(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching opettajat:", e)
      })
  }, [])

  const handleChange = (event, isExpanded) => {
    console.log(isExpanded)
    setExpanded(isExpanded);
  };

  const handleSelected = (teacher) => {
    console.log(teacher)
    console.log('Hihi', teacher.nick_name)
  }

  const handleCreateTeacherOpen = () => {
    console.log('sup')
  }

  const handleCreateTeacherClose = () => {
    console.log('sup')
  }

  if (loading) {
    return (
      <span>Loading...</span>
    )
  }
  return (
    <Accordion TransitionProps={{ unmountOnExit: true }} expanded={expanded} onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={teachers.uid + "-content"}
        id={teachers.uid + "-panel"}
      >
        <Typography sx={{ width: '15%', flexShrink: 0 }}>
          Opettajat
        </Typography>
      </AccordionSummary>
      <AccordionDetails id='teacher-scrollable'>
        <Stack spacing={2} alignItems="center" mt={2}>
          <Stack direction="row" spacing={2} >
            <GeneralSearch
              data={teachers}
              search={setTeacherSearch}
              search_keys={["nick_name", "first_name", "last_name", "grp", "status_date", "status_text", "discord_id", "user.email"]}
              autocomplete_key={"nick_name"}
            />
            <Button variant="contained" onClick={handleCreateTeacherOpen}>
              Lisää opettaja
            </Button>

          </Stack>
          <InfiScrollCardGrid
            card={TeacherCard}
            data={teacherSearch}
            handleSelected={handleSelected}
            idKey='user_id'

            itemScroll={12}
            scrollableTarget="teacher-scrollable"
            scrollThreshold="100px"
          />
        </Stack>
        <NewTeacherModal
          handleOpen={handleCreateTeacherOpen}
          handleClose={handleCreateTeacherClose}
          open={createTeacherOpen}
        />
      </AccordionDetails>
    </Accordion >
  );

}

