import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Slider from '@mui/material/Slider'

import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { formatISO } from 'date-fns'

import SelectStudentDialog from '../Dialogs/SelectStudentDialog'
import SelectTeacherDialog from '../Dialogs/SelectTeacherDialog'
import SelectWorktasksDialog from '../Dialogs/SelectWorkTasksDialog'
import SelectWorkInstructorDialog from '../Dialogs/SelectWorkInstructorDialog'
import SelectCompetenceDialog from '../Dialogs/SelectCompetenceDialog'
import SelectDegreePartDialog from '../Dialogs/SelectDegreePartDialog'
import WorkTaskList from '../Lists/WorkTaskList.js'
import WorkInstructorList from '../Lists/WorkInstructorList'
import CompetenceList from '../Lists/CompetenceList'
import DegreePartList from '../Lists/DegreePartList'

import { useParams } from 'react-router-dom';

// import Input from '@mui/material/Input'
import { styled } from '@mui/material/styles';

// import { Context } from "../../context";
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: ['100%', '80%'],
  height: ['100%', '80%'],
  maxHeight: ['100%', '80%'],
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: ['100%', '80%'],
  overflow: 'scroll'
};

const Input = styled('input')({
  display: 'none',
});

export default function NewWorkPeriodModal({ handleOpen, handleClose, open, tp_id = null }) {

  const [uploadPath, setUploadPath] = useState("")
  const [uploadError, setUploadError] = useState(false)
  const [alkupvm, setAlkupvm] = useState(null)
  const [loppupvm, setLoppupvm] = useState(null)
  // Open states
  const [openSelectStudent, setOpenSelectStudent] = useState(false)
  const [openSelectTeacher, setOpenSelectTeacher] = useState(false)
  const [openSelectWorktasks, setOpenSelectWorktasks] = useState(false)
  const [openSelectWorkInstructors, setOpenSelectWorkInstructors] = useState(false)
  const [openSelectCompetences, setOpenSelectCompetences] = useState(false)
  const [openSelectDegreeParts, setOpenSelectDegreeParts] = useState(false)

  // Data states
  const [student, setStudent] = useState({ nick_name: "" })
  const [teacher, setTeacher] = useState({ nick_name: "" })
  const [worktasks, setWorktasks] = useState([])
  const [instructors, setInstructors] = useState([])
  const [competences, setCompetences] = useState([])
  const [degreeParts, setDegreeParts] = useState([])


  const [workPeriodData, setWorkPeriodData] = useState({
    tp_id: null,
    alku_pvm: null,
    loppu_pvm: null,
    lisatieto: null,
    opiskelija_uid: null,
    opettaja_id: null,
    tyotehtavat: null,
    topohjaajat: null,
    tutkinnonosat: null,
    osaamiset: null
  })

  const handleCreateClick = async (e) => {
    const wp = {
      tp_id: tp_id,
      alku_pvm: alkupvm,
      loppu_pvm: loppupvm,
      lisatieto: workPeriodData.lisatieto,
      opiskelija_uid: student.uid,
      opettaja_id: teacher.id,
      tyotehtavat: worktasks.map(x => x.id),
      topohjaajat: instructors.map(x => x.id),
      osaamiset: competences.map(x => x.oid),
      tutkinnonosat: degreeParts.map(x => x.to_id)
    }

    axios.post(process.env.REACT_APP_API_BASE + '/topjaksot', wp)
      .then(res => {
        console.log(res.data)
        handleClose(true)
      }).catch(e => {
        console.log(e)
      })
  }

  const handleUpload = ({ target }) => {
    if (target.files.length > 1) {
      setUploadPath("Too many files selected")
      setUploadError(true)
      return
    }


    console.log(target.files[0].name)
    setUploadPath(target.files[0].name)
  }

  const handleAlkupvmChange = (date) => {
    setAlkupvm(formatISO(date, { representation: 'date' }))
  }

  const handleLoppupvmChange = (date) => {
    setLoppupvm(formatISO(date, { representation: 'date' }))
  }


  // useEffect(() => {
  //   
  // })

  const handleSelectStudentClick = () => {
    setOpenSelectStudent(true)
  }

  const handleSelectTeacherClick = () => {
    setOpenSelectTeacher(true)
  }

  const handleSelectWorktasksClick = () => {
    setOpenSelectWorktasks(true)
  }

  const handleSelectWorkInstructorsClick = () => {
    setOpenSelectWorkInstructors(true)
  }

  const handleSelectCompetencesClick = () => {
    setOpenSelectCompetences(true)
  }

  const handleSelectDegreePartsClick = () => {
    setOpenSelectDegreeParts(true)
  }

  const handleChange = ({ target }) => {
    let value
    if (target.value == "") {
      value = null
    } else {
      value = target.value
    }
    let data = workPeriodData
    data[target.id] = value
    setWorkPeriodData(data)
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid container rowSpacing={2} columnSpacing={2} >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid item xs={12} sm={6}>

                  <DesktopDatePicker
                    label="Alku Päivä"
                    inputFormat="dd/MM/yyyy"
                    value={alkupvm}
                    onChange={handleAlkupvmChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DesktopDatePicker
                    label="Loppu Päivä"
                    inputFormat="dd/MM/yyyy"
                    value={loppupvm}
                    onChange={handleLoppupvmChange}
                    renderInput={(params) => <TextField fullWidth {...params} />}
                  />
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="lisatieto"
                  label="Lisätieto"
                  placeholder="Lisää joku lisätieto"
                  multiline
                  minRows={2}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    disabled
                    required
                    fullWidth
                    variant="filled"
                    id="outlined-disabled"
                    label="Opiskelija"
                    value={student.nick_name}
                  />
                  <Button variant="contained" onClick={handleSelectStudentClick}>
                    Valitse
                  </Button>
                  <SelectStudentDialog
                    open={openSelectStudent}
                    setOpen={setOpenSelectStudent}
                    setStudent={setStudent}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <TextField
                    disabled
                    required
                    fullWidth
                    variant="filled"
                    id="outlined-disabled"
                    label="Opettaja"
                    value={teacher.nick_name}
                  />
                  <Button variant="contained" onClick={handleSelectTeacherClick}>
                    Valitse
                  </Button>
                  <SelectTeacherDialog
                    open={openSelectTeacher}
                    setOpen={setOpenSelectTeacher}
                    setTeacher={setTeacher}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack alignItems="center" spacing={2}>

                  <WorkTaskList tasks={worktasks} />


                  <Button sx={{ width: "50%" }} variant="contained" onClick={handleSelectWorktasksClick}>
                    Valitse työtehtävät
                  </Button>
                </Stack>
                <SelectWorktasksDialog
                  open={openSelectWorktasks}
                  setOpen={setOpenSelectWorktasks}
                  setWorktasks={setWorktasks}
                  worktasks={worktasks}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack alignItems="center" spacing={2}>

                  <WorkInstructorList instructors={instructors} />


                  <Button sx={{ width: "50%" }} variant="contained" onClick={handleSelectWorkInstructorsClick}>
                    Valitse työpaikan ohjaajat
                  </Button>
                </Stack>
                <SelectWorkInstructorDialog
                  open={openSelectWorkInstructors}
                  setOpen={setOpenSelectWorkInstructors}
                  setInstructors={setInstructors}
                  instructors={instructors}
                  tp={tp_id}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack alignItems="center" spacing={2}>

                  <CompetenceList competences={competences} />


                  <Button sx={{ width: "50%" }} variant="contained" onClick={handleSelectCompetencesClick}>
                    Valitse osaamiset
                  </Button>
                </Stack>
                <SelectCompetenceDialog
                  open={openSelectCompetences}
                  setOpen={setOpenSelectCompetences}
                  setCompetences={setCompetences}
                  competences={competences}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Stack alignItems="center" spacing={2}>

                  <DegreePartList degreeParts={degreeParts} />


                  <Button sx={{ width: "50%" }} variant="contained" onClick={handleSelectDegreePartsClick}>
                    Valitse tutkinnonosat
                  </Button>
                </Stack>
                <SelectDegreePartDialog
                  open={openSelectDegreeParts}
                  setOpen={setOpenSelectDegreeParts}
                  setDegreeParts={setDegreeParts}
                  degreeParts={degreeParts}
                />
              </Grid>
              <Box sx={{ mx: 'auto', pt: 2 }}>
                <Button
                  onClick={handleCreateClick}
                  variant="contained">Luo Topjakso</Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div >
  );
}
