import React from 'react'

import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'


import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

export default function SimpleInstructorPaper({ instructor }) {

  return (
    <Paper>
      <Typography variant="h5">
        {instructor.first_name} {instructor.last_name}
      </Typography>
      {instructor.puhelin && (
        <Typography variant="body1" display="inline">
          <Link variant='inherit' underline='hover'
            href={'tel:' + instructor.puhelin} >
            <PhoneIcon fontSize='small' color='primary' style={{ verticalAlign: "middle" }} />
            {' ' + instructor.puhelin}
          </Link>
        </Typography>
      )}
      <Typography />
      {instructor.email && (
        <Typography variant="body1" display="inline">
          <Link variant='inherit' underline='hover'
            href={'mailto:' + instructor.email} >
            <EmailIcon fontSize='small' color='primary' style={{ verticalAlign: "middle" }} />
            {' ' + instructor.email}
          </Link>
        </Typography>
      )}
    </Paper>
  )
}
