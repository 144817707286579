import React, { useState, useRef } from 'react'
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";



function Notification({ message, status, open, handleClose }) {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      open={open}
      autoHideDuration={3500} //calls onClose after 3500ms (3.5secs)
      onClose={handleClose}
    >
      <MuiAlert elevation={6} variant="filled" severity={status}>
        {message}
      </MuiAlert>
    </Snackbar>
  )
}

export const NotificationContext = React.createContext()
export function NotificationProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [messageData, setMessageData] = useState(undefined)
  const queueRef = useRef([])


  function createNotification(notification) {
    queueRef.current.push(notification)

    if (open) {
      // If it is already open, close it, calls handleExited.
      setOpen(false)
    } else {
      // If not opened already, process your notification.
      processQueue()
    }
  }

  function handleClose(event, reason) {
    if (reason === 'clickaway') {
      // To make sure that the notification stays on
      // the page no matter if we click somewhere else
      return
    }
    setOpen(false)
  }

  function processQueue() {
    if (queueRef.current.length > 0) {
      setMessageData(queueRef.current.shift())
      setOpen(true)
    }
  }

  return (
    <NotificationContext.Provider value={{ createNotification }}>
      {children}
      <Notification {...messageData}
        open={open}
        handleClose={handleClose} />
    </NotificationContext.Provider>
  )
}
