import React, { Fragment, Component, useState, useEffect } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";
import axios from 'axios'


import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function CompanyGridSearch({ data, search }) {
  let names = data.map(company => company.nimi)
  names = [...new Set(names)]

  // search("hello")

  // const searchChange = (e) => {
  //   search(e.target.value)
  // }

  return (
    <Box
      sx={{
        width: "60%",
      }}
    >
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        options={names}
        onInputChange={(e, newInputValue) => {
          search(newInputValue);
        }}
        onChange={(e, newValue) => {
          search(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search input"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Box>
  )
}
