import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import Fuse from 'fuse.js'

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  limit: 10,
});

export default function GeneralSearch({ data, search, search_keys, autocomplete_key }) {
  const [names, setNames] = useState(() => data.map(item => item[autocomplete_key]).filter(x => x))


  useEffect(() => {
    let nm = data.map(item => item[autocomplete_key]).filter(x => x)
    nm = [...new Set(nm)]
    setNames(nm)
  }, [data])

  const generalSearch = (value) => {
    if (!value) {
      search(data);
      return;
    }
    const fuse = new Fuse(data, {
      keys: search_keys,
      threshold: 0.1,
    });

    const result = fuse.search(value);
    const matches = [];
    if (!result.length || result.length == 0) {
      search([]);
    } else {
      result.forEach(({ item }) => {
        matches.push(item);
      });
      search(matches);
    }
  };

  return (
    <Box
      sx={{
        width: "60%",
      }}
    >
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        options={names}
        filterOptions={filterOptions}
        onInputChange={(e, newInputValue) => {
          generalSearch(newInputValue);
        }}
        onChange={(e, newValue) => {
          generalSearch(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search input"
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
      />
    </Box>
  )
}

