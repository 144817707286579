
import React, { useState, useContext } from 'react';
import BaseEditor from '../Editor/BaseEditor'

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Paper from '@mui/material/Paper'

import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';

import Avatar from '@mui/material/Avatar';
import { red, green, yellow } from '@mui/material/colors';

import CardHeader from '@mui/material/CardHeader';

import { formatDistanceToNow } from 'date-fns'
import { fi } from 'date-fns/locale'

import useAuth from 'context/useAuth'
import GeneralService from 'services/general.service'
import { NotificationContext } from 'context/SnackbarProvider.js'

export default function CommentCard({ data: comment, refresh }) {

  const getSince = (aika) => {
    return formatDistanceToNow(new Date(aika), { addSuffix: true, locale: fi })
  }

  const [commentUser] = useState(() => {
    return [
      [comment.opettaja, 'opettaja'],
      [comment.opiskelija, 'opiskelija'],
      [comment.ohjaaja, 'topohjaaja']
    ].find((x) => x[0])
  })

  const { user } = useAuth()
  const { createNotification } = useContext(NotificationContext)

  try {

    var name = commentUser[0].first_name + ' ' + commentUser[0].last_name
    var initials = commentUser[0].first_name[0] + commentUser[0].last_name[0]
  } catch (e) {
    console.log(e)
  }
  var sinceCommented = comment.kommentti_pvm
    ? getSince(comment.kommentti_pvm)
    : ""

  const checkOwner = () => {
    if (user.userType == commentUser[1]) {
      if (commentUser[1] === 'opiskelija') {
        if (commentUser[0].uid === user.selfId) {
          return true
        }
      } else {
        if (commentUser[0].id === user.selfId) {
          return true
        }
      }
    }
    return false
  }

  const deleteComment = () => {
    GeneralService.deleteWorkperiodComment(comment.id)
      .then(res => {
        createNotification({ message: "Kommentti poistettu!", status: "success" })

        refresh()
      })
      .catch(e => {
        createNotification({ message: "Kommentin poistossa tapahtui virhe!", status: "error" })
      })

  }

  return (
    <Card elevation={3}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: yellow[500] }} aria-label="useravatar">
            {initials}
          </Avatar>
        }
        title={name}
        sx={{ pb: 0 }}
        subheader={sinceCommented}
        action={
          checkOwner() && (
            <Tooltip title='Poista kommentti'>
              <IconButton onClick={deleteComment} aria-label="delete">
                <DeleteIcon sx={{ color: red[600] }} />
              </IconButton>
            </Tooltip>
          )
        }
      />


      <CardContent>
        <Paper>
          <BaseEditor content={JSON.parse(comment.kommentti)} />
        </Paper>
      </CardContent>
    </Card >
  );
}
