import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import axios from "axios"

import InfiScrollCardGrid from '../Grid/InfiScrollCardGrid'
import StudentCard from 'components/Cards/Select/StudentCard'
import GeneralSearch from '../Searches/GeneralSearch'


export default function SelectStudentDialog({ open, setOpen, setStudent }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [loading, setLoading] = useState(true)
  const [students, setStudents] = useState([])
  const [studentSearch, setStudentSearch] = useState([])

  useEffect(() => {
    axios(process.env.REACT_APP_API_BASE + '/opiskelija/all')
      .then(res => {
        console.log("Opiskelijat", res.data)
        setStudents(res.data.data)
        setStudentSearch(res.data.data)
        setLoading(false)
      })
      .catch(e => {
        console.log("Error fetching students: ", e)
      })
  }, [])

  const handleClose = () => {
    setOpen(false)
  };

  const handleSelected = (student) => {
    console.log("Meh", student)
    setStudent(student)
    handleClose()
  }

  if (loading) {
    return (<div> Loading... </div>)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth='sm'
      >
        <DialogTitle id="responsive-dialog-title">
          {"Valitse opsieklija"}
        </DialogTitle>
        <DialogContent id="student-scrollable">
          <Stack spacing={2} alignItems="center" mt={2}>
            <GeneralSearch
              data={students}
              search={setStudentSearch}
              search_keys={["nick_name", "first_name", "last_name", "grp", "status_date", "status_text", "discord_id", "user.email"]}
              autocomplete_key={"nick_name"}
            />
            <InfiScrollCardGrid
              card={StudentCard}
              data={studentSearch}
              handleSelected={handleSelected}
              idKey='uid'

              itemScroll={12}
              scrollableTarget="student-scrollable"
              scrollThreshold="100px"
            />

            {/* <StudentSearchGrid data={studentSearch} setStudent={setStudent} handleClose={handleClose} /> */}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
