import React, { Fragment, useState, useEffect, useContext } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams } from 'react-router-dom';

import CssBaseline from "@mui/material/CssBaseline";
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'

// import Sidebar from "components/Sidebar/Sidebar";

import ISCompetenceAccordion from 'components/Accordions/Instructor/ISCompetenceAccordion'
import WorkPeriodPlanAccordion from 'components/Accordions/WorkPeriod/WorkPeriodPlanAccordion'

import WorkperiodSummary from 'components/Papers/WorkperiodSummary'
import WorkPeriodWorklogAccordion from 'components/Accordions/WorkPeriod/WorkPeriodWorklogAccordion'

import useAuth from "context/useAuth";
import InstructorService from 'services/instructor.service'

export default function InstructorFrontpage(props) {
  let { uid } = useParams()

  const [workperiod, setWorkperiod] = useState()
  const [refresh, setRefresh] = useState(false)

  console.log('UID:', uid)
  useEffect(() => {
    InstructorService.getStudent(uid)
      .then(res => {
        console.log('Got data successfully')
        res.data.data[0].suunnittelu = JSON.parse(res.data.data[0].suunnittelu)
        setWorkperiod(res.data.data[0])
        console.log('Top workperiod', res.data.data[0])
      })
      .catch(e => {
        console.log("Error fetching company:", e)
      })
  }, [refresh]);

  const handleRefresh = () => {
    console.log('hello refresh')
    setRefresh(!refresh)
  }

  return (
    <Fragment>
      <HelmetProvider>
        <Helmet>
          <title>Ohjaaja | Opiskelija</title>
        </Helmet>
      </HelmetProvider>
      {/* <Sidebar ifUserIs={"opettaja"} /> */}

      <CssBaseline />
      <Container maxWidth="md" disableGutters={true}>
        <Box justifyContent="center" mt={2}>
          <Stack spacing={2} alignItems="center" >
            <Typography variant="h5" component="div">
              {"Työpaikka harjoittelujakso"}
            </Typography>

            <WorkperiodSummary workperiod={workperiod} refresh={handleRefresh} />
            <WorkPeriodPlanAccordion workperiod={workperiod} refresh={handleRefresh} />
            <ISCompetenceAccordion workperiod={workperiod} />
            <WorkPeriodWorklogAccordion workperiod={workperiod} refresh={handleRefresh} />
          </Stack>
        </Box>
      </Container>
    </Fragment>
  )



}
