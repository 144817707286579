import React, { useState, useContext, useEffect } from 'react';

import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { NotificationContext } from "../../context/SnackbarProvider";

import axios from 'axios'

export default function TopPaikkaForms({ company }) {


  const [topPaikkaData, setTopPaikkaData] = useState()
  const { createNotification } = useContext(NotificationContext)

  useEffect(() => {
    setTopPaikkaData(company)
  }, [])

  const onSaveClick = async () => {
    axios.put(process.env.REACT_APP_API_BASE + '/toppaikat/' + company.tp_id, topPaikkaData)
      .then(res => {
        console.log(res.data)
        createNotification({ message: "Työpaikka muokattu", status: "success" })
      }).catch(e => {
        console.log(e)
      })
  }

  const handleChange = ({ target }) => {
    let value
    if (target.value == "") {
      value = null
    } else {
      value = target.value
    }
    let data = topPaikkaData
    data[target.id] = value
    setTopPaikkaData(data)
  }

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid container rowSpacing={2} columnSpacing={2} >
        <Grid item xs={12} sm={6}>
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              required
              id="nimi"
              label="Nimi"
              variant="filled"
              defaultValue={company.nimi}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            error
            id="videoesittely"
            label="Video Esittely"
            defaultValue={company.videoesittely}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="esittely"
            label="Esittely"
            placeholder="Lisää joku esittely"
            multiline
            minRows={3}
            defaultValue={company.esittely}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="yhteystiedot"
            label="Yhteystiedot"
            placeholder="Yhteystiedot"
            multiline
            minRows={2}
            defaultValue={company.yhteystiedot}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="sijainti"
            label="Sijainti"
            placeholder="Sijainti"
            multiline
            minRows={2}
            defaultValue={company.sijainti}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="vaadittuosaaminen"
            label="Vaadittu Osaaminen"
            variant="outlined"
            defaultValue={company.vaadittuosaaminen}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            id="korttikoulutukset"
            label="Korttikoulutukset"
            variant="outlined"
            defaultValue={company.korttikoulutukset}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            error
            id="360linkki"
            label="360 Link"
            defaultValue={company['360linkki']}
            onChange={handleChange}
          />
        </Grid>

        <Box sx={{ mx: 'auto', pt: 2 }}>
          <Button
            onClick={onSaveClick}
            variant="contained">Tallenna</Button>
        </Box>
      </Grid>
    </Grid>
  )
}


