
import React, { Fragment, Component, useState, useEffect } from 'react';
// import Sidebar from "../../components/Sidebar/Sidebar";


import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'

import { CardActionArea } from '@mui/material';


export default function SimpleWorkPeriodCard({ data: workperiod, handleSelected }) {

  return (
    <Card sx={{ minWidth: 200, maxWidth: 350 }}>
      <CardActionArea onClick={() => handleSelected(workperiod)}>
        <CardContent>
          <Grid item container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {"Alkupäivä: "}
              </Typography>
              <Typography variant="body1" noWrap display="inline">
                {workperiod.alku_pvm}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {"Loppupäivä: "}
              </Typography>
              <Typography variant="body1" noWrap display="inline">
                {workperiod.loppu_pvm}
              </Typography>
              <Typography />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {"Opettaja: "}
              </Typography>
              <Typography variant="body1" noWrap display="inline">
                {workperiod.opettaja.first_name + ' ' + workperiod.opettaja.last_name}
              </Typography>
              <Typography />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {" Ohjaajat: "}
              </Typography>
              {workperiod.topohjaajat.map(instructor => (
                <Typography key={instructor.id} variant="body1" noWrap>
                  {instructor.first_name + ' ' + instructor.last_name}
                </Typography>
              ))}
              <Typography />
            </Grid>
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 600 }} variant="body1" display="inline">
                {" Lisätieto: "}
              </Typography>
              <Typography variant="body1" noWrap display="inline">
                {workperiod.lisatieto}
              </Typography>
              <Typography />
            </Grid>
          </Grid>

        </CardContent>
      </CardActionArea>
    </Card>
  );
}
