
import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import CompetenceControlList from 'components/Lists/CompetenceControlList'
import CompetenceControlListStars from 'components/Lists/CompetenceControlListStars'

export default function ISCompetenceAccordion({ workperiod }) {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (event, isExpanded) => {
    console.log(isExpanded)
    setExpanded(isExpanded);
  };

  return (
    <>
      {workperiod
        ? <Accordion sx={{ width: "100%" }}
          expanded={expanded} onChange={handleChange}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={workperiod.tj_id + "-content"}
            id={workperiod.tj_id + "-panel"}
          >
            <Typography variant="h6">
              Hankittavat Osaamiset
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{
            pt: '16px', pl: { xs: 0, sm: '16px' },
            pr: { xs: 0, sm: '16px' }
          }} >
            <CompetenceControlListStars competences={workperiod.osaamiset} />
          </AccordionDetails>
        </Accordion>
        : <Skeleton width={'100%'} height={56} />
      }
    </>

  );
}
